/* eslint-disable no-param-reassign */
import m from 'mithril';

import { t, setSelectedLanguage } from '../../translation-files/translator';
import apirequest from '../../utils/api-service';
import instituationConfig from '../../instituation-config';

const country = instituationConfig[window.country || 'kenya'];

export default {
  oncreate() {
    window.KTComponents.init();
    document.body.className = '';
    document.body.style.backgroundImage = "url('assets/media/auth/bg-ug.jpg')";
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundRepeat = 'no-repeat';
    document.body.style.backgroundPosition = 'center';
    document.body.style.display = 'flex';
    document.body.style.alignItems = 'flex-end';
    document.body.style.justifyContent = 'center';
    document.querySelector('.app-page').style.display = 'flex';

    // center in page vertically
    document.querySelector('.app-page').style.justifyContent = 'start';
    document.getElementById('kt_app_root').style.paddingRight = '5%';
    document.getElementById('kt_app_root').style.paddingTop = '5%';
    document.getElementById('kt_app_root').style.paddingLeft = '5%';

    const appDiv = document.getElementById('app');
    if (appDiv) {
      // appDiv.style.width = '450px';
      // appDiv.style.minWidth = '450px';
      // appDiv.style.maxWidth = '450px';
    }

    document.body.style.backgroundSize = '105vw'; // set background size to 100% of viewport width
    document.body.style.backgroundPosition = 'center -13vw'; // adjust the vertical position of the background image
  },
  oninit: async ({ state }) => {
    state.logos = {
      dark: 'assets/media/logos/bill-logo.svg',
      light: 'assets/media/logos/bill-logo.svg',
    };

    state.loading = false;
    state.error = false;
    state.success = false;
    state.successMessage = '';
    state.errorMessage = '';
    state.step = 1;
    state.cardno = '';
    state.form_data_valid = false;
    state.form_valid = false;
    state.form_data = {
      serial: '',
      mobile: '',
      phone: '',
    };
    state.form_pin = {
      pin: '',
      repin: '',
    };
    state.form_errors = {
      serial: false,
      mobile: false,
      phone: false,
    };
    state.form_pin_errors = {
      pin: false,
      repin: false,
    };
    state.otp = '';
    state.calling_code = country.calling_code || '254';

    state.onchange = (e) => {
      if (e.target.name === 'mobile' || e.target.name === 'phone') {
        const pattern = /^\d{0,9}$/;
        let val = e.target.value;
        val = pattern.test(val) ? val : state.form_data[e.target.name];

        if (val.startsWith('0') && val.length == 9) {
          val = val.substring(1);
        }
        state.form_data[e.target.name] = val;
      } else {
        state.form_data[e.target.name] = e.target.value;
      }
      state.validateOnChange();
    };

    state.validateOnChange = () => {
      const pattern = /^\d{9}$/;
      state.form_errors = {
        mobile: !pattern.test(state.form_data.mobile),
        phone: state.form_data.phone !== state.form_data.mobile,
        serial: state.form_data.serial.length === 0,
      };
      state.form_valid = !state.form_errors.serial && !state.form_errors.phone && !state.mobile;
      m.redraw();
    };
    state.handleInputValidations = () => {
      const pattern = /^\d{4}$/;
      state.form_pin_errors.pin = !pattern.test(state.form_pin.pin);
      state.form_pin_errors.repin = state.form_pin.pin !== state.form_pin.repin;
      state.form_data_valid = !state.form_pin_errors.pin && !state.form_pin_errors.repin;
    };

    state.onpinchange = (e) => {
      const pattern = /^\d{0,4}$/;
      state.form_pin[e.target.name] = pattern.test(e.target.value) ? e.target.value : state.form_pin[e.target.name];
      state.handleInputValidations();
    };

    state.initCardActivation = async () => {
      try {
        state.loading = true;

        const {
          respcode,
          respmsg,
        } = await apirequest(`/UserController/initCardActivation`, 'POST', {
          MOBNUMBER: `${state.calling_code}${state.form_data.mobile}`,
          SLNUMBER: state.form_data.serial,
        });
        state.loading = false;

        if (respcode === 0) {
          state.step = 2;
          state.error = false;
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        m.redraw();
      } catch (error) {
        console.log(error);
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Failed to initialize card activation';
      }
    };

    state.validateCardActivation = async () => {
      try {
        state.loading = true;

        const {
          respcode,
          respmsg,
          respbody,
        } = await apirequest(`/UserController/validateCardActivation`, 'POST', {
          MOBNUMBER: `${state.calling_code}${state.form_data.mobile}`,
          SLNUMBER: state.form_data.serial,
          OTP: state.otp,
        });
        state.loading = false;

        if (respcode === 0) {
          state.cardno = respbody.CARDNUMBER;
          state.step = 3;
          state.error = false;
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        m.redraw();
      } catch (error) {
        console.log(error);
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Failed to validate OTP';
      }
    };

    state.doCardActivation = async () => {
      try {
        state.loading = true;

        const {
          respcode,
          respmsg,
        } = await apirequest(`/UserController/doCardActivation`, 'POST', {
          MOBNUMBER: `${state.calling_code}${state.form_data.mobile}`,
          SLNUMBER: state.form_data.serial,
          PIN: state.form_pin.pin,
          CARDNUMBER: state.cardno,
        });
        state.loading = false;

        if (respcode === 0) {
          state.success = true;
          state.successMessage = respmsg;
          state.error = false;
          setTimeout(() => {
            m.route.set('/');
          }, 2000);
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        m.redraw();
      } catch (error) {
        console.log(error);
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Failed to activate Card';
      }
    };
    m.redraw();
  },
  onremove() {
    document.body.className = '';
    document.body.style.backgroundImage = '';
    document.body.style.backgroundSize = '';
    document.body.style.backgroundRepeat = '';
    document.body.style.backgroundPosition = '';
    document.body.style.display = '';
    document.body.style.alignItems = '';
    document.body.style.justifyContent = '';

    document.querySelector('.app-page').style.display = '';
    document.querySelector('.app-page').style.justifyContent = '';

    document.getElementById('kt_app_root').style.paddingLeft = '';
    document.getElementById('kt_app_root').style.paddingTop = '';

    const appDiv = document.getElementById('app');
    if (appDiv) {
      appDiv.style.width = '';
      appDiv.style.minWidth = '';
      appDiv.style.maxWidth = '';
    }
  },
  view({ state }) {
    return [
      m('div', {
        class: 'd-flex align-items-center justify-content-center flex-column',
      },
      m('div', { class: 'card shadow-sm' },
        [
          m('div', { class: 'card-body' }, [

            m('label', {
              class: 'card-title text-center',
              style: {
                'font-size': '2rem',
                'font-weight': '850',
                display: 'block',
                'text-align': 'center',
              },
            },
            t().auth_login.login.label),
            state.step === 1 && [
              m('div', { class: 'card-title text-center', style: { 'font-size': '1.5rem' } },
                m('span', {
                  class: 'text-truncate',
                  style: {
                    'text-overflow': 'ellipsis',
                    overflow: 'hidden',
                    'white-space': 'wrap',
                    'max-width': '100px', // adjust the width to change the truncation limit
                  },
                },
                'Card Activation')),
              (state.success && [m('div', { class: 'text-success text-center fs-5 py-4' }, state.successMessage)]),

              m('form', {
                class: 'form w-100 fv-plugins-bootstrap5 fv-plugins-framework', novalidate: 'novalidate', id: 'kt_sign_in_form', 'data-kt-redirect-url': '/keen/demo7/index.html', action: '#',
              },
              [
                m('div', { class: 'mb-10' },
                  [
                      m('label', { class: 'required form-label' },
                      'Serial No'),
                      m('input', {
                        class: 'form-control',
                        type: 'text',
                        name: 'serial',
                        required: true,
                        placeholder: 'Serial No',
                        value: state.form_data.serial,
                        oninput: e => state.onchange(e),
                      }),
                      state.form_errors.serial && [
                        m('div', { class: 'text-danger fs-6 py-2' }, 'Serial No is required'),
                      ],

                      m('label', { class: 'required form-label', for: '' },
                      'Mobile Number'),
                        m('div', { class: 'input-group' }, [
                          m('span', { class: 'input-group-text', id: 'mobile' }, `+${state.calling_code}`),
                          m('input', {
                            class: 'form-control',
                            type: 'text',
                            name: 'mobile',
                            required: true,
                            placeholder: '712345678',
                            value: state.form_data.mobile,
                            oninput: e => state.onchange(e),
                          }),
                        ]),
                        state.form_errors.mobile && [
                          m('div', { class: 'text-danger fs-6 py-2' }, 'Enter valid Mobile No 0712...'),
                        ],

                      m('label', { class: 'required form-label', for: '' },
                      'Confirm Mobile Number'),
                      m('div', { class: 'input-group' }, [
                        m('span', { class: 'input-group-text', id: 'phone' }, `+${state.calling_code}`),
                        m('input', {
                          class: 'form-control',
                          type: 'text',
                          name: 'phone',
                          id: 'phone',
                          required: true,
                          placeholder: '712345678',
                          value: state.form_data.phone,
                          oninput: e => state.onchange(e),
                        }),
                      ]),
                      state.form_errors.phone && [
                        m('div', { class: 'text-danger fs-6 py-2' }, 'Mobile and Confirm mobile number should match'),
                      ],
                  ]),
                m('div', { class: 'd-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8' },
                  [
                    m('a', { class: 'link-primary', href: '/' },
                      'Back'),
                  ]),

                m('div', { class: 'd-grid mb-5' },
                  m('button', {
                    class: 'btn btn-primary',
                    type: 'submit',
                    id: 'kt_sign_in_submit',
                    disabled: state.loading || !state.form_valid,
                    onclick(e) {
                      e.preventDefault();
                      state.initCardActivation();
                    },
                  },
                  [
                    ...(!state.loading ? [m('span', { class: 'indicator-label' },
                      'Submit')]
                    : [m('span', { class: '' },
                      [
                        ' Please wait... ',
                        m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2' }),
                      ])]),
                  ])),
                (state.error && [m('div', { class: 'text-danger text-center fs-5 py-2' }, state.errorMessage)]),

              ]),
            ],
            state.step === 2 && [
              m('div', { class: 'card-title text-center', style: { 'font-size': '1.5rem' } },
                m('span', {
                  class: 'text-truncate',
                  style: {
                    'text-overflow': 'ellipsis',
                    overflow: 'hidden',
                    'white-space': 'wrap',
                    'max-width': '100px', // adjust the width to change the truncation limit
                  },
                },
                'Card Activation')),
              [m('div', { class: 'text-success text-center fs-5 py-4' }, 'An OTP has been sent to your registered mobile number')],

              m('form', {
                class: 'form w-100 fv-plugins-bootstrap5 fv-plugins-framework', novalidate: 'novalidate', id: 'kt_sign_in_form', 'data-kt-redirect-url': '/keen/demo7/index.html', action: '#',
              },
              [
                m('div', { class: 'mb-10' },
                  [
                    m('input', {
                      class: 'form-control',
                      type: 'text',
                      name: 'otp',
                      required: true,
                      placeholder: '',
                      value: state.otp,
                      oninput: (e) => {
                        const pattern = /^\d{0,6}$/;
                        state.otp = pattern.test(e.target.value) ? e.target.value : state.otp;
                       },
                    }),
                    state.otp.length < 6 && m('div', { class: 'text-danger fs-6' }, 'OTP should be 6 digit code'),
                  ]),
                m('div', { class: 'd-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8' },
                  [
                    m('button', { class: 'btn btn-light link-primary', onclick: () => { state.step = 1; } },
                      'Back'),
                  ]),

                m('div', { class: 'd-grid mb-5' },
                  m('button', {
                    class: 'btn btn-primary',
                    type: 'submit',
                    id: 'kt_sign_in_submit',
                    disabled: state.loading || state.otp.length < 4,
                    onclick(e) {
                      e.preventDefault();
                      state.validateCardActivation();
                    },
                  },
                  [
                    ...(!state.loading ? [m('span', { class: 'indicator-label' },
                      'Submit')]
                    : [m('span', { class: '' },
                      [
                        ' Please wait... ',
                        m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2' }),
                      ])]),
                  ])),
                (state.error && [m('div', { class: 'text-danger text-center fs-5 py-2' }, state.errorMessage)]),

              ]),

            ],
            state.step === 3 && [
              m('div', { class: 'card-title text-center', style: { 'font-size': '1.5rem' } },
                m('span', {
                  class: 'text-truncate',
                  style: {
                    'text-overflow': 'ellipsis',
                    overflow: 'hidden',
                    'white-space': 'wrap',
                    'max-width': '100px', // adjust the width to change the truncation limit
                  },
                },
                'Card Activation Pin')),
              (state.success && [m('div', { class: 'text-success text-center fs-5 py-4' }, state.successMessage)]),

              m('form', {
                class: 'form w-100 fv-plugins-bootstrap5 fv-plugins-framework', novalidate: 'novalidate', id: 'kt_sign_in_form', 'data-kt-redirect-url': '/keen/demo7/index.html', action: '#',
              },
              [
                m('div', { class: 'mb-10' },
                  [
                      m('label', { class: 'required form-label' },
                      'Pin'),
                      m('input', {
                        class: 'form-control',
                        type: 'password',
                        name: 'pin',
                        required: true,
                        placeholder: 'Pin',
                        value: state.form_pin.pin,
                        oninput: e => state.onpinchange(e),
                      }),
                      state.form_pin_errors.pin && [
                        m('div', { class: 'text-danger fs-6 py-2' }, 'Pin should be a 4 digit code'),
                      ],

                      m('label', { class: 'required form-label', for: '' },
                      'Confirm Pin'),
                        m('input', {
                          class: 'form-control',
                          type: 'password',
                          name: 'repin',
                          required: true,
                          placeholder: 'Re-enter Pin',
                          value: state.form_pin.repin,
                          oninput: e => state.onpinchange(e),
                        }),
                        state.form_pin_errors.repin && [
                          m('div', { class: 'text-danger fs-6 py-2' }, 'Pin does not match'),
                        ],

                  ]),
                m('div', { class: 'd-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8' },
                  [
                    m('button', { class: 'btn btn-light link-primary', onclick: () => { state.step = 1; } },
                      'Back'),
                  ]),

                m('div', { class: 'd-grid mb-5' },
                  m('button', {
                    class: 'btn btn-primary',
                    type: 'submit',
                    id: 'kt_sign_in_submit',
                    disabled: state.loading || !state.form_data_valid,
                    onclick(e) {
                      e.preventDefault();
                      state.doCardActivation();
                    },
                  },
                  [
                    ...(!state.loading ? [m('span', { class: 'indicator-label' },
                      'Submit')]
                    : [m('span', { class: '' },
                      [
                        ' Please wait... ',
                        m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2' }),
                      ])]),
                  ])),
                (state.error && [m('div', { class: 'text-danger text-center fs-5 py-2' }, state.errorMessage)]),

              ]),
            ],
          ]),

        ])),
    ];
  },
};
