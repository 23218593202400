
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import domtoimage from 'dom-to-image';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import instituationConfig from '../instituation-config';

export const country = instituationConfig[window.country || 'kenya'];
const currency = country.CURRENCY || 'KES';

export const setToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const getItemLocalStorage = key => JSON.parse(localStorage.getItem(key));

export const getAccountData = () => {
  return getItemLocalStorage('accountData') || null;
}

// export const formatAmount = amount => `${currency} ${new Intl.NumberFormat(country.code || 'en-KE').format(amount)}`;
export const formatAmount = (amount) => {
  // save the original value
  const original_amount = amount;
  // Check if the amount is a string and try to convert it to a number
  if (typeof amount === 'string') {
    amount = parseFloat(amount.replace(/[^0-9.]/g, ''));
  }

  // Check if the amount is a valid number
  if (isNaN(amount)) {
    return original_amount;
  }

  // Format the amount
  return `${currency} ${new Intl.NumberFormat(country.code || 'en-KE').format(amount)}`;
};

export function formatTimestamp(milliseconds) {
  const date = new Date(milliseconds);
  const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // Use 24-hour format
  };
  return date.toLocaleString('en-US', options);
}

export const isValidPhoneNumber = (phoneNumber) => {
    const pattern = /^\d{12}/;

    return pattern.test(phoneNumber);
  };

export const isValidAmount = (amount) => {
    // const pattern = /^\d*$/;
    const pattern = /^\d+(\.\d{1,2})?$/;

    return pattern.test(amount) ? (Number(amount) > 0) : false;
};

export const isEmailValid = (email) => {
    const pattern = /\S+@\S+\.\S+/;

    return pattern.test(email);
};

export function downloadPdfReport(base64String, filename) {
    // Create a link element
    const link = document.createElement('a');

    // Set the title of the link
    link.title = filename;

    // Set the href to the base64 string
    link.href = `data:application/pdf;base64,${base64String}`;
    const date = new Date();
    const fileName = `${filename
        }_${
        date.getFullYear()
        }${(`0${date.getMonth() + 1}`).slice(-2)
        }${(`0${date.getDate()}`).slice(-2)
        }${(`0${date.getHours()}`).slice(-2)
        }${(`0${date.getMinutes()}`).slice(-2)
        }${(`0${date.getSeconds()}`).slice(-2)
        }`;

    // Set the download attribute to the filename
    link.download = fileName;

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
}

export function downloadXSheetReport(base64, filename) {
    // Decode the base64 string to binary string
    const binaryString = window.atob(base64);
    // Create an 8-bit unsigned array from the binary string
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    // Create a Blob object from the bytes
    const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    // Create an anchor element and trigger a download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const date = new Date();
    const fileName = `${filename
        }_${
        date.getFullYear()
        }${(`0${date.getMonth() + 1}`).slice(-2)
        }${(`0${date.getDate()}`).slice(-2)
        }${(`0${date.getHours()}`).slice(-2)
        }${(`0${date.getMinutes()}`).slice(-2)
        }${(`0${date.getSeconds()}`).slice(-2)
        }`;
    link.download = fileName;
    link.click();
  }

export function exportTable(table, filename = 'excel-file.xls') {
    const tableHTML = table.outerHTML.replace(/\s/g, '%20');

    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    const dataType = 'application/vnd.ms-excel';

    if (navigator.msSaveOrOpenBlob) {
      const blob = new Blob(['\ufeff', tableHTML], {
        type: dataType,
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      downloadLink.href = `data:${dataType}, ${tableHTML}`;

      downloadLink.download = filename;

      downloadLink.click();
    }
  }

export function downloadPdfFromHtml(el, filName) {
    /** WITH CSS */
    domtoimage.toPng(el)
    .then(async (dataUrl) => {
        const img = new Image();
        img.src = dataUrl;
        img.onload = () => {
            const doc = new jsPDF({
                orientation: 'landscape',
                unit: 'px',
                format: [img.width, img.height],
            });
            doc.addImage(img, 'JPEG', 0, 0, img.width, img.height);
            const date = new Date();
            const filename = `${filName}_${
                date.getFullYear()
                }${(`0${date.getMonth() + 1}`).slice(-2)
                }${(`0${date.getDate()}`).slice(-2)
                }${(`0${date.getHours()}`).slice(-2)
                }${(`0${date.getMinutes()}`).slice(-2)
                }${(`0${date.getSeconds()}`).slice(-2)
                }.pdf`;
            doc.save(filename);
        };
    })
    .catch((error) => {
        console.error('oops, something went wrong!', error);
    });
}

export async function downloadPdf(fileName) {
  try {
    const headerEl = document.getElementById('table-header');
    const dateHeaderEl = document.getElementById('date-header');
    const padding = 20; 
    let startY = 0;
    
    // Temporarily apply inline styles to the header element
    const originalHeaderStyle = headerEl.style.cssText;
    headerEl.style.color = '#000000';
    headerEl.style.fontSize = '1px'

    // Capture the header
    const headerCanvas = await html2canvas(headerEl, { scrollX: -window.scrollX, scrollY: -window.scrollY, scale: 1 });
    const headerImgData = headerCanvas.toDataURL('image/png');
    const headerWidth = headerCanvas.width;
    const headerHeight = headerCanvas.height;
    startY += headerHeight + padding;
    
    // Restore the original styles
    headerEl.style.cssText = originalHeaderStyle;

    // Create a new PDF document with a larger format
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: 'a3', // Use a3 format for more space
    });

    // Add the header image to the PDF
    pdf.addImage(headerImgData, 'PNG', padding, padding, headerWidth, headerHeight);

    if (dateHeaderEl) {  // Temporarily apply inline styles to the header element
      const originalDateStyle = dateHeaderEl.style.cssText;
      dateHeaderEl.style.color = '#000000';
      dateHeaderEl.style.fontSize = '1.5px';
      dateHeaderEl.style.fontWeight = '900';
      const dateCanvas = await html2canvas(dateHeaderEl, { scrollX: -window.scrollX, scrollY: -window.scrollY, scale: 8 });
      const dateImgData = dateCanvas.toDataURL('image/png');
      const dateWidth = dateCanvas.width;
      const dateHeight = dateCanvas.height;

      // Add the header image to the PDF
      pdf.addImage(dateImgData, 'PNG', padding, startY, dateWidth, dateHeight);
      startY += dateHeight + padding;
      
      // Restore the original styles
      dateHeaderEl.style.cssText = originalDateStyle;
    }

    // Use autoTable to add the table content with adjusted settings
    pdf.autoTable({
      html: '#table',
      startY,
      margin: { top: padding, left: padding, right: padding, bottom: padding },
      theme: 'grid',
      headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      styles: { fontSize: 10 }, // Reduce font size
      columnStyles: {
        0: { cellWidth: 'auto' }, // Adjust column widths as needed
        1: { cellWidth: 'auto' },
        2: { cellWidth: 'auto' },
        // Add more column styles if needed
      },
    });

    // Generate the filename
    const date = new Date();
    const filename = `${fileName}_${date.getFullYear()}${(`0${date.getMonth() + 1}`).slice(-2)}${(`0${date.getDate()}`).slice(-2)}${(`0${date.getHours()}`).slice(-2)}${(`0${date.getMinutes()}`).slice(-2)}${(`0${date.getSeconds()}`).slice(-2)}.pdf`;

    // Save the PDF
    pdf.save(filename);
    return 0;
  } catch (error) {
    console.error(error);
    return 1;
  }
}

export function handleXlsxUpload(event, callback) {
  try {
    const file = event.target.files[0];
    const reader = new FileReader();
    console.log('file', file);

    reader.onload = function (e) {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      workbook.SheetNames.forEach((sheetName) => {
        // Convert the sheet data to JSON
        const XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
        callback(XL_row_object);
      });
    };

    reader.onerror = function (ex) {
      console.log(ex);
    };

    reader.readAsBinaryString(file);
  } catch (error) {
    console.log(error);
    callback([]);
  }
}

export function downloadSheetFromJson(data) {
  try {
  // Convert JSON data to worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Create a new workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const date = new Date();
    const filename = `sample_${
          date.getFullYear()
          }${(`0${date.getMonth() + 1}`).slice(-2)
          }${(`0${date.getDate()}`).slice(-2)
          }${(`0${date.getHours()}`).slice(-2)
          }${(`0${date.getMinutes()}`).slice(-2)
          }${(`0${date.getSeconds()}`).slice(-2)
          }.xlsx`;

          // Write the workbook to a file
          XLSX.writeFile(workbook, filename);
    } catch (error) {
      console.error('oops, something went wrong!', error);
    }
}

export async function downloadSheetReportFromJson(data, title, name) {
  try {
       // Create a new workbook and append the worksheet
       const workbook = XLSX.utils.book_new();
       const worksheet = XLSX.utils.json_to_sheet([]);

       // Add title to the first row
    XLSX.utils.sheet_add_aoa(worksheet, [[title]], { origin: 'A1' });

        // Merge cells for the title
        if (!worksheet['!merges']) worksheet['!merges'] = [];
        worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 9 } });

        // Apply bold formatting to the title
        if (!worksheet.A1) worksheet.A1 = {};
        worksheet.A1.s = {
          font: {
            bold: true,
            sz: 14,
          },
          alignment: {
            horizontal: 'center',
          },
        };

    // Add data starting from the second row
    XLSX.utils.sheet_add_json(worksheet, data, { origin: 'A2', skipHeader: false });

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

       // Convert the workbook to a binary string
       const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

       // Convert binary string to array buffer
       const s2ab = (s) => {
         const buf = new ArrayBuffer(s.length);
         const view = new Uint8Array(buf);
         for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
         return buf;
       };

       // Save the workbook to a file
       const date = new Date();
       const filename = `${name}_${
         date.getFullYear()
       }${(`0${date.getMonth() + 1}`).slice(-2)
       }${(`0${date.getDate()}`).slice(-2)
       }${(`0${date.getHours()}`).slice(-2)
       }${(`0${date.getMinutes()}`).slice(-2)
       }${(`0${date.getSeconds()}`).slice(-2)
       }.xlsx`;

       saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), filename);

       // Add a logo to the workbook
       const img = new Image();
       img.src = 'assets/media/logos/rubis-logo-white.png';
       img.onload = async () => {
         const canvas = await html2canvas(img);
         const imgData = canvas.toDataURL('image/png');
         // Add the image data to the workbook
         // Note: This part requires additional handling to embed the image in the XLSX file
       };
       return 0;
    } catch (error) {
      console.error('oops, something went wrong!', error);
      return 1;
    }
}

// Function to generate random color for each line
function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function generateDatesForMonth(selectMonth) {
  const [year, monthStr] = selectMonth.split('-');
  const monthIndex = parseInt(monthStr, 10) - 1; // Convert month to zero-based index
  const date = new Date(year, monthIndex, 1);
  const dates = [];

  while (date.getMonth() === monthIndex) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const yearN = date.getFullYear();
    dates.push(`${day}-${month}-${yearN}`);
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

export function generateDatesForWeek(selectWeek) {
  const [year, weekStr] = selectWeek.split('-W');
  const week = parseInt(weekStr, 10);
  const date = new Date(year, 0, 1 + (week - 1) * 7);
  const dayOfWeek = date.getDay();
  const ISOweekStart = date;

  if (dayOfWeek <= 4) {
    ISOweekStart.setDate(date.getDate() - date.getDay() + 1);
  } else {
    ISOweekStart.setDate(date.getDate() + 8 - date.getDay());
  }

  const dates = [];
  for (let i = 0; i < 7; i++) {
    const day = String(ISOweekStart.getDate()).padStart(2, '0');
    const month = String(ISOweekStart.getMonth() + 1).padStart(2, '0');
    const yearN = ISOweekStart.getFullYear();
    dates.push(`${day}-${month}-${yearN}`);
    ISOweekStart.setDate(ISOweekStart.getDate() + 1);
  }
  console.log('dataes:', dates);

  return dates;
}

export function getCurrentWeek() {
  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const pastDaysOfYear = (currentDate - startOfYear) / 86400000;
  const weekNumber = Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
  const year = currentDate.getFullYear();
  const weekString = String(weekNumber).padStart(2, '0');

  return `${year}-W${weekString}`;
}

export function generateDateArray(start, end) {
  const startDateParts = start.split('-');
  const endDateParts = end.split('-');
  const startDate = new Date(`${startDateParts[1]}/${startDateParts[2]}/${startDateParts[0]}`);
  const endDate = new Date(`${endDateParts[1]}/${endDateParts[2]}/${endDateParts[0]}`);
  const dateArray = [];

  while (startDate <= endDate) {
      const day = String(startDate.getDate()).padStart(2, '0');
      const month = String(startDate.getMonth() + 1).padStart(2, '0');
      const year = startDate.getFullYear();
      dateArray.push(`${day}-${month}-${year}`);
      startDate.setDate(startDate.getDate() + 1);
  }

  return dateArray;
}

export function serializeMinistatement (data) {
  // group by RRN
  let data_arr = data.reduce((acc, item) => {
    const idx = acc.findIndex(ac => ac.RRN == item.RRN);
    let obj = {};
    if (idx < 0) {
      obj = {
        ...item,
        total: Number(item.ENTRY_AMOUNT),
        datetime: generateTimestamps({ date: item.TRAN_DATE, time: item.TRAN_TIME})
      }
      acc.push(obj);
    } else {
      let trx = acc[idx]
      obj = {
        ...trx,
        total: trx.total + Number(item.ENTRY_AMOUNT)
      }
      acc[idx] = obj;
    }
    return acc;
  }, []);

  return data_arr;
}

export function serializeDealerMinistatement (data) {
  // group by RRN
  let data_arr = data.reduce((acc, item) => {
    const idx = acc.findIndex(ac => ac.RRN == item.RRN);
    let obj = {};
    if (idx < 0) {
      obj = {
        ...item,
        total: Number(item.TXNAMOUNT),
        datetime: generateDealerTimestamps({ date: item.TXNDATE, time: item.TXNTIME})
      }
      acc.push(obj);
    } else {
      let trx = acc[idx]
      obj = {
        ...trx,
        total: trx.total + Number(item.TXNAMOUNT)
      }
      acc[idx] = obj;
    }
    return acc;
  }, []);

  return data_arr;
}

export function generateTimestamps ({ date, time }) {

  // Reformat the date to 'YYYY-MM-DD'
  const [day, month, year] = date.split('-');
  const formattedDate = `${year}-${month}-${day}`;

  // Combine date and time
  const dateTimeString = `${formattedDate}T${time}`;

  // Create a new Date object
  const timestamp = new Date(dateTimeString);

  // Get the timestamp in milliseconds
  const milliseconds = timestamp.getTime();
  return milliseconds;
}

export function generateDealerTimestamps ({ date, time }) {

  // Reformat the date to 'YYYY-MM-DD'
  const [year, month, day] = date.split('-');
  const formattedDate = `${year}-${month}-${day}`;

  // Combine date and time
  const dateTimeString = `${formattedDate}T${time}`;

  // Create a new Date object
  const timestamp = new Date(dateTimeString);

  // Get the timestamp in milliseconds
  const milliseconds = timestamp.getTime();
  return milliseconds;
}

export function serializeTransactions(data = [], dates = []) {
  // extract labels;
  const groupedData = {};
  data.forEach((transaction) => {
      const cardNum = transaction.MASKED_CARD;
      if (!groupedData[cardNum]) {
          groupedData[cardNum] = [];
      }
      groupedData[cardNum].push({
          date: transaction.datetime,
          amount: parseFloat(transaction.total),
      });
  });

  const datasets = Object.keys(groupedData).map((cardNum) => {
    const amounts = dates.map((date) => {
        const transaction = groupedData[cardNum].find(t => t.date === date);
        return transaction ? transaction.amount : 0;
    });
    return {
        name: cardNum,
        data: amounts,
        fill: false,
        borderColor: getRandomColor(),
    };
  });
  return { datasets, labels: dates };
}

export function serializeChartData(data = [], dates = []) {
  // extract labels;
  const groupedData = {};
  let stamps = new Set();
  data.forEach((transaction) => {
      const cardNum = transaction.MASKED_CARD;
      if (!groupedData[cardNum]) {
          groupedData[cardNum] = [];
      }
      groupedData[cardNum].push({
          date: transaction.datetime,
          amount: parseFloat(transaction.total).toFixed(2),
      });
      stamps.add(transaction.datetime);
  });
  stamps = [...stamps].sort((a, b) => a - b)
  
  // Object.keys(groupedData).forEach(card => {
  //   let c_stamps = groupedData[card];
  //   let n_stamps = [...stamps].reduce((acc, item) => {
  //     const c_item = c_stamps.find(c => c.date === item);
  //     if (c_item) {
  //       acc.push(c_item)
  //     } else {
  //       acc.push({date: item, amount: 0})
  //     }
  //     return acc;
  //   }, []);
  //   groupedData[card] = n_stamps;
  // })

  const datasets = Object.keys(groupedData).map((cardNum) => {
    const dt = groupedData[cardNum].map((i) => {
        return {x: i.date, y: i.amount};
    });
    return {
        name: cardNum,
        data: dt,
        fill: false,
        borderColor: getRandomColor(),
    };
  });
  return { datasets, labels: dates };
}

export function serializeDealerChartData(data = [], dates = []) {
  // extract labels;
  const groupedData = {};
  data.forEach((transaction) => {
      const product = transaction.PRODUCT;
      if (!groupedData[product]) {
          groupedData[product] = [];
      }
      groupedData[product].push({
          date: transaction.datetime,
          amount: parseFloat(transaction.total).toFixed(2),
      });
  });

  const datasets = Object.keys(groupedData).map((product) => {
    const dt = groupedData[product].map((i) => {
        return {x: i.date, y: i.amount};
    });
    return {
        name: product.toLocaleLowerCase() === 'na' ? 'OTHERS' : product,
        data: dt,
        fill: false,
        borderColor: getRandomColor(),
    };
  });
  return { datasets, labels: dates };
}

export function serializeDealerTransactions(data, dates) {
  // extract labels;
  const groupedData = {};
  data.forEach((transaction) => {
      const txnDesc = transaction.TXN_DESC;
      if (!groupedData[txnDesc]) {
          groupedData[txnDesc] = [];
      }
      groupedData[txnDesc].push({
          date: transaction.TRAN_DATE,
          amount: parseFloat(transaction.AMOUNT).toFixed(2),
      });
  });

  const datasets = Object.keys(groupedData).map((txnDesc) => {
    const amounts = dates.map((date) => {
        const transaction = groupedData[txnDesc].find(t => t.date === date);
        return transaction ? transaction.amount : 0;
    });
    return {
        name: txnDesc,
        data: amounts,
        fill: false,
        borderColor: getRandomColor(),
    };
  });
  return { datasets, labels: dates };
}

export function hasResource(id) {
  const accountData = getItemLocalStorage('accountData');
  return !!accountData?.MENULIST?.find(menu => menu.MENUID === id);
}

export function debounce(func, timeout = 500) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export const getCardStrings = (cards) => {
     const cardsString = cards.reduce((acc, card) => `${acc === '' ? acc : `${acc},`}'${card.id || card.ENCCARDNUMBER}'`, '');
     return cardsString;
   };

export const getPagination = (currentPage, totalPages) => {
  const visiblePages = 5;
  const pages = [];

  if (totalPages <= visiblePages) {
    // If total pages are less than or equal to visible pages, show all pages
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    // Always show the first page
    pages.push(1);

    // Determine the range of pages to show around the current page
    let startPage = Math.max(2, currentPage - 1);
    let endPage = Math.min(totalPages - 1, currentPage + 1);

    // Adjust start and end pages if they are too close to the boundaries
    if (currentPage <= 2) {
      endPage = 4;
    }
    if (currentPage >= totalPages - 1) {
      startPage = totalPages - 3;
    }

    // Add ellipsis if needed
    if (startPage > 2) {
      pages.push('0');
    }

    // Add the range of pages
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Add ellipsis if needed
    if (endPage < totalPages - 1) {
      pages.push('0');
    }

    // Always show the last page
    pages.push(totalPages);
  }
console.log('pages', pages)
 return pages;
}