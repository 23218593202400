import axios from 'axios';
import config from '../config';
import instituationConfig from '../instituation-config';
import { getAccountData } from './helper';

const sendLogToServer = async (logData) => {
  try {
    // const res = await axios({
    //   url: 'http://127.0.0.1:3030/log',
    //   method: 'POST',
    //   data: logData,
    // });
    console.log('=======log res======');
  } catch (error) {
    console.error('Error sending log to server:', error);
  }
};

async function makeRequest(endpoint, method = 'GET', data = {}, customHeaders = {}) {
  const instituation = instituationConfig[window.country || 'kenya'];
  const baseUrl = config[window.env].API_URL || 'https://rubisuat.volanedev.com/rubis';
  const headers = { 'Content-Type': 'application/json', ...customHeaders };
  const accountData = getAccountData() || {};
  const body = data ? { respbody: {
     INSTID: instituation.ID || 'RUBISKE',
     USERNAME: accountData?.USERNAME,
      ...data,
    } } : {};
  const url = `${baseUrl}${endpoint}`;

  try {
    // Log the request
    const requestLog = {
      level: 'info',
      url,
      method,
      data: body,
    };
    // sendLogToServer(requestLog);

    const response = await axios({
      url,
      method,
      headers,
      data: body,
    });

    // Log the response
    const responseLog = {
      level: 'info',
      url,
      method,
      data: response.data,
    };
    // sendLogToServer(responseLog);

    return response.data;
  } catch (error) {
    console.error(error);
     // Log the error response
    const errorLog = {
      level: 'error',
      url,
      method,
      body: error.message,
    };
    // sendLogToServer(errorLog);

    throw error;
  }
}

export default makeRequest;
