/* eslint-disable no-param-reassign */
import m from 'mithril';
import moment from 'moment';
import XLSX from 'xlsx';
import header from '../../components/header';
import table_data from '../../components/table_data';
import selectOption from './components/selectOptions';
import selectCards from './components/selectCards';
import selectFileType from './components/selectFiletype';

import { t } from '../../translation-files/translator';
import footer from '../../components/footer';
import {
  country, isValidAmount, getItemLocalStorage, downloadPdfReport, downloadXSheetReport, downloadPdf, formatAmount, downloadSheetReportFromJson, getCardStrings
} from '../../utils/helper';
import apirequest from '../../utils/api-service';

export default {
  async oninit({ state }) {
    const accountData = getItemLocalStorage('accountData') || {};
    const globalCardType = getItemLocalStorage('globalCardType');
    const stationList = getItemLocalStorage('stationList') || [];
    const cardListData = getItemLocalStorage('cardListData');

    const { CARDLIST, CARD_LIST } = cardListData || {};
    state.cards = CARDLIST || (CARD_LIST || []);
    state.SEL_CARDNOLIST = '';
    state.accountData = accountData;
    state.loading = false;
    state.form_valid = false;
    state.form_valid = false;
    state.success = false;
    state.successMessage = '';
    state.error = false;
    state.errorMessage = '';
    state.downloading = false;
    state.downloadError = false;
    state.downloadErrorMsg = "Error downloading the report"
    state.download_type = 'pdf';
    state.isreport = false;
    state.statements = [];
    state.tableData = {};
    state.path = '';
    state.filename = '';
    state.title = '';
    state.id = 'CLI_CARD_REPORT';
    state.closingBal = 0;
    state.openingBal = 0;
    state.startDate = '';
    state.endDate = '';
    state.reportPayload = {
      CARD_TXNFAILD_RPT: {
        CUSTID: accountData.CUSTID,
        CARDNO: accountData.CARDNO,
      },
      CARD_TXN: {
        CUSTID: accountData.CUSTID,
        CARDNO: accountData.CARDNO,
      },
      CARD_SUMM_RPT: {
        CUSTID: accountData.CUSTID,
        CARDNO: accountData.CARDNO,
      },
      CARD_DETAIL_STMT: {
        CUSTID: accountData.CUSTID,
        CARDNO: accountData.CARDNO,
      },
      CLI_TXNFAILD_RPT: {
        CARDNO: accountData.CARDNO,
      },
      CLI_ANOMLY_RPT: {
        CARDNO: accountData.CARDNO,
      },
      CLI_RUNNING_RPT: {
        CARDNO: accountData.CARDNO,
      },
      CLI_SUMM_RPT: {
        CARDNO: accountData.CARDNO,
        ACCTNO: accountData.ACCTNO,
      },
      CLI_CARD_LIST: {
        CARDNO: accountData.CARDNO,
        ACCTNO: accountData.ACCTNO,
      },
      CLI_CARD_REPORT: {
        CARDNO: accountData.CARDNO,
        ACCTNO: accountData.ACCTNO,
      }
    };
    state.handleInputValidations = () => {
      state.form_errors.amount = !isValidAmount(state.form_data.amount);
      state.form_valid = !state.form_errors.amount;
    };

    state.mapTableData = (statements, type, label) => {
      let labels = [];
      let data = [];
      let report_data = [];
      const title = label;
      if (type === 'running-balance') {
       labels = [
         {
           label: t().cards.card_list_table.card_number,
           data_key: 'card_number',
         }, {
           label: t().cards.card_list_table.vehicle_reg,
           data_key: 'vehicle_reg',
         }, {
           label: t().cards.card_list_table.card_name,
           data_key: 'card_name',
         }, {
           label: 'Closing Balance',
           data_key: 'closing_bal',
         }, {
           label: 'Opening Balance',
           data_key: 'opening_bal',
         }, {
           label: 'Purchases',
           data_key: 'purchases',
         }, {
          label: 'Purch Reversal',
          data_key: 'purchase_rev',
        },
         ...(globalCardType === '@PRE' ? [{
           label: 'Top ups',
           data_key: 'topups',
         }, {
          label: 'Topup reversal',
          data_key: 'reversal',
        }] : []), {
          label: 'Oth. debit',
          data_key: 'oth_debit',
        }, {
          label: 'Oth. credit',
          data_key: 'oth_credit',
        },
         {
           label: 'Status',
           data_key: 'status',
         },
       ];

        data = statements.map(card => ({
          card_number: card.CARD_NO,
          vehicle_reg: card.VEHICLE_NO,
          card_name: card.CARD_NAME,
          closing_bal: !card.CLOSING_BALANCE || card.CLOSING_BALANCE === '-' ? '-' : formatAmount(card.CLOSING_BALANCE || 0),
          opening_bal: !card.OPENING_BAL || card.OPENING_BAL === '-' ? '-' : formatAmount(card.OPENING_BAL || 0),
          purchases: !card.PURCHASES || card.PURCHASES === '-' ? '-' : formatAmount(card.PURCHASES || 0),
          purchase_rev: !card.PURCHASE_REVER || card.PURCHASE_REVER === '-' ? '-' : formatAmount(card.PURCHASE_REVER || 0),
          topups: !card.TOP_UPS || card.TOP_UPS === '-' ? '-' : formatAmount(card.TOP_UPS || 0),
          reversal: !card.TOP_UPS_REVER || card.TOP_UPS_REVER === '-' ? '-' : formatAmount(card.TOP_UPS_REVER || 0),
          oth_debit: !card.OTHERS_DEBIT || card.OTHERS_DEBIT === '-' ? '-' : formatAmount(card.OTHERS_DEBIT || 0),
          oth_credit: !card.OTHERS_CREDIT || card.OTHERS_CREDIT === '-' ? '-' : formatAmount(card.OTHERS_CREDIT || 0),
          status: m('span', { class: `px-2 rounded ${card.CARD_STATUS === 'Active' ? 'text-success border-success' : 'text-danger border-danger'}` }, card.CARD_STATUS),
        }));

        report_data = statements.map(card => ({
          'Card Number': card.CARD_NO,
          'Rehicle Reg': card.VEHICLE_NO,
          'Card Name': card.CARD_NAME,
          'Closing Bal': !card.CLOSING_BALANCE || card.CLOSING_BALANCE === '-' ? '-' : Number(card.CLOSING_BALANCE || 0),
          'Opening bal': !card.OPENING_BAL || card.OPENING_BAL === '-' ? '-' : Number(card.OPENING_BAL || 0),
          Purchases: !card.PURCHASES || card.PURCHASES === '-' ? '-' : Number(card.PURCHASES || 0),
          'Purchase Reversal': !card.PURCHASE_REVER || card.PURCHASE_REVER === '-' ? '-' : Number(card.PURCHASE_REVER || 0),
          Topups: !card.TOP_UPS || card.TOP_UPS === '-' ? '-' : Number(card.TOP_UPS || 0),
          Reversal: !card.TOP_UPS_REVER || card.TOP_UPS_REVER === '-' ? '-' : Number(card.TOP_UPS_REVER || 0),
          'Other Debit': !card.OTHERS_DEBIT || card.OTHERS_DEBIT === '-' ? '-' : Number(card.OTHERS_DEBIT || 0),
          'Other Credit': !card.OTHERS_CREDIT || card.OTHERS_CREDIT === '-' ? '-' : Number(card.OTHERS_CREDIT || 0),
          Status: card.CARD_STATUS,
        }));
      }
      if (type === 'transactions-report') {
       labels = [{
            label: 'Date & Time',
            data_key: 'date',
          }, {
            label: t().transactions.transaction_table_labels.card_number,
            data_key: 'card_number',
          }, {
            label: 'Card Name',
            data_key: 'card_name',
          }, {
            label: 'TXN Amount',
            data_key: 'amount',
          }, {
            label: 'Pump price',
            data_key: 'price',
          }, {
            label: t().transactions.transaction_table_labels.station,
            data_key: 'station',
          }, {
            label: 'Current Odometer',
            data_key: 'ODOMETER',
          },{
            label: 'Prev Odometer',
            data_key: 'ODOMETER_PREVS',
          },{
            label: 'Mileage',
            data_key: 'Mileage',
          },  {
            label: 'TXN Type / Product',
            data_key: 'txn_type',
          }, {
            label: 'DR/CR',
            data_key: 'op_type',
          }, {
            label: 'RRN',
            data_key: 'rrn',
          }, {
            label: 'Quantity',
            data_key: 'QUANTITY',
          }, {
            label: 'Balance',
            data_key: 'BALANCE',
          }, {
            label: 'Driver code',
            data_key: 'DRCODE',
          }, {
            label: 'Vehicle Reg No',
            data_key: 'VEH_NUM',
          },
        ];

        data = statements.map(({
          TRAN_DATE, STATION, TRAN_TIME, ENTRY_AMOUNT, PRICE, ODOMETER, ODOMETER_PREVS, RRN, CARD_NUM, CARD_NAME, TXN_TYPE, OPERATION_TYPE, QUANTITY, BALANCE, DRCODE, VEH_NUM
        }) => ({
          date: `${TRAN_DATE} : ${TRAN_TIME}`,
          card_number: CARD_NUM,
          card_name: CARD_NAME,
          station: STATION,
          amount: formatAmount(ENTRY_AMOUNT),
          price: formatAmount(PRICE),
          ODOMETER,
          ODOMETER_PREVS,
          Mileage: isNaN(Number(ODOMETER) - Number(ODOMETER_PREVS)) ? '' : Number(ODOMETER) - Number(ODOMETER_PREVS),
          rrn: RRN,
          txn_type: TXN_TYPE,
          op_type: OPERATION_TYPE === 'CR' ? 'Credit' : 'Debit',
          QUANTITY,
          BALANCE: formatAmount(BALANCE),
          DRCODE,
          VEH_NUM
        }));

        report_data = statements.map(({
          TRAN_DATE, STATION, TRAN_TIME, ENTRY_AMOUNT, PRICE, ODOMETER, ODOMETER_PREVS, RRN, CARD_NUM, CARD_NAME, TXN_TYPE, OPERATION_TYPE, QUANTITY, BALANCE, DRCODE, VEH_NUM
        }) => ({
          'Txn Date/Time': `${TRAN_DATE} : ${TRAN_TIME}`,
          'Card Number': CARD_NUM,
          'Card Name': CARD_NAME,
          'Station': STATION,
          'Amount': Number(ENTRY_AMOUNT),
          'Pump price': Number(PRICE),
          'Current Odometer reading': ODOMETER,
          'Previous Odometer reading': ODOMETER_PREVS,
          'Mileage': isNaN(Number(ODOMETER) - Number(ODOMETER_PREVS)) ? '' : Number(ODOMETER) - Number(ODOMETER_PREVS),
          'RRN': RRN,
          'TXN Type / Product': TXN_TYPE,
          'Operation Type': OPERATION_TYPE === 'CR' ? 'Credit' : 'Debit',
          'Quantity': Number(QUANTITY),
          'BALANCE': Number(BALANCE),
          'Driver code': DRCODE,
          'Vehicle Reg No': VEH_NUM
        }));
      }
      if (type === 'txn-report') {
       labels = [{
        label: 'Date & Time',
        data_key: 'date',
      }, {
        label: t().transactions.transaction_table_labels.card_number,
        data_key: 'card_number',
      }, {
        label: t().transactions.transaction_table_labels.station,
        data_key: 'station',
      }, {
        label: 'RRN',
        data_key: 'rrn',
      }, {
        label: 'Product',
        data_key: 'product',
      }, {
        label: 'Txn Type',
        data_key: 'txn_type',
      }, {
        label: 'DR/CR',
        data_key: 'op_type',
      }, {
        label: 'Amount',
        data_key: 'amount',
      }, {
        label: 'Balance',
        data_key: 'balance',
      }
        ];

        data = statements.map(({
          TRAN_DATE, STATION, TRAN_TIME, ENTRY_AMOUNT, RRN, CARD_NUM, PRODUCT, TXN_TYPE, OPERATION_TYPE, BALANCE,
        }) => ({
          date: `${TRAN_DATE} : ${TRAN_TIME}`,
          card_number: CARD_NUM,
          product: PRODUCT,
          station: STATION,
          amount: formatAmount(ENTRY_AMOUNT),
          balance: formatAmount(BALANCE),
          rrn: RRN,
          txn_type: TXN_TYPE,
          op_type: OPERATION_TYPE,
        }));

        report_data = statements.map(({
          TRAN_DATE, STATION, TRAN_TIME, ENTRY_AMOUNT, RRN, CARD_NUM, PRODUCT, TXN_TYPE, OPERATION_TYPE, BALANCE,
        }) => ({
          'Date & Time': `${TRAN_DATE} : ${TRAN_TIME}`,
          'Card number': CARD_NUM,
          'Product': PRODUCT,
          'Station': STATION,
          'Amount': Number(ENTRY_AMOUNT),
          'RRN': RRN,
          'TXN Type': TXN_TYPE,
          'Operation Type': OPERATION_TYPE,
          'Balance': Number(BALANCE)
        }));
      }
      if (type === 'card-list') {
       labels = [{
            label: t().cards.card_list_table.card_number,
            data_key: 'card_number',
          },
          {
            label: t().cards.card_list_table.vehicle_reg,
            data_key: 'vehicle_reg',
          }, {
            label: t().cards.card_list_table.card_name,
            data_key: 'card_name',
          },
          {
            label: t().cards.card_list_table.mobile_no,
            data_key: 'mobile_no',
          },
          ...(state.globalCardType === '@POST' ? [
            {
              label: 'Monthly Limit',
              data_key: 'month_limit',
            },
          ] : []),
            {
              label: 'Monthly Limit',
              data_key: 'month_limit',
            }, {
            label: 'Card Balance',
            data_key: 'card_bal',
          }, {
            label: 'Status',
            data_key: 'status',
          },
       ];

        data = statements.map(card => ({
          card_number: card.MASKEDCARD || card.CARDNUMBER,
          serial_num: card.CARD_SERIAL_NUM,
          vehicle_reg: card.VEHREGNUM || 'N/A',
          card_name: card.CUSTNAME || card.CARDNAME,
          card_bal: isNaN(card.BALANCE || card.AVLBAL) ? (card.BALANCE || card.AVLBAL) : formatAmount(card.BALANCE || card.AVLBAL || 0),
          month_limit: formatAmount(card.MONTHLYLIMT || 0),
          mobile_no: card.MOBILENUM,
          status: m('span', { class: `px-2 rounded ${card.STATUS === '07' ? 'text-success border-success' : 'text-danger border-danger'}` }, card.STATUS === '05' && !card.CARDNUM && state.accountData.USERTYPE === '@CLIENT' ? 'Pending Activation' : card.STATUSDESC),
        }));

        report_data = statements.map(card => ({
          'Card Number': card.MASKEDCARD || card.CARDNUMBER,
          'Serial num': card.CARD_SERIAL_NUM,
          'Customer name': card.CUSTNAME,
          'Vehicle Reg': card.VEHREGNUM || 'N/A',
          DRIVERCODE: card.DRIVERCODE,
          'Card name': card.CUSTNAME || card.CARDNAME,
          'Card Balance': isNaN(card.BALANCE || card.AVLBAL) ? (card.BALANCE || card.AVLBAL) : Number(card.BALANCE || card.AVLBAL || 0),
          ...(state.globalCardType === '@POST' ? { month_limit: formatAmount(card.MONTHLYLIMT || 0) } : {}),
          mobile_no: card.MOBILENUM,
          CARDTYPE: card.CARDTYPE,
          status: card.STATUSDESC,
        }));
      }

      if (type === 'summary-report') {
        labels = [
          {
            label: 'Product Quantity',
            data_key: 'PRODUCT_QUANTITY',
          }, {
            label: 'Transaction Count',
            data_key: 'TXN_CNT',
          }, {
            label: 'Transaction Description',
            data_key: 'TXN_DESC',
          }, {
            label: `Amount (${country.CURRENCY})`,
            data_key: 'TXN_AMT',
          },
        ];

        data = statements.map(stat => ({ ...stat, PRODUCT_QUANTITY: Number(stat.PRODUCT_QUANTITY).toFixed(2) }));
        report_data = statements.map(item => ({
          'Product Quantity': Number(item.PRODUCT_QUANTITY).toFixed(2),
          'Transaction Count': item.TXN_CNT,
          'Transaction Description': item.TXN_DESC,
          [`Amount (${country.CURRENCY})`]: item.TXN_AMT,
        }));
      }

      if (type === 'transaction-summary-report') {
        labels = [
          {
            label: 'Product',
            data_key: 'PRODUCT',
          },
          {
            label: 'Product Quantity',
            data_key: 'PRODUCT_QUANTITY',
          }, {
            label: 'TXN Count',
            data_key: 'COUNT',
          }, {
            label: `Amount (${country.CURRENCY})`,
            data_key: 'AMOUNT',
          },
        ];

        data = statements;
        report_data = statements.map(item => ({
          PRODUCT_QUANTITY: item.PRODUCT_QUANTITY,
          PRODUCT: item.PRODUCT,
          COUNT: item.COUNT,
          AMOUNT: formatAmount(item.AMOUNT),
        }));
      }

      if (type === 'detailed-report') {
        labels = [
          {
            label: 'Card number',
            data_key: 'CARD_NUM',
          },
          {
            label: 'Card name',
            data_key: 'CARDNAME',
          },
          {
            label: 'Date & Time',
            data_key: 'DATETIME',
          }, {
            label: 'TXN Type',
            data_key: 'OPERATION_TYPE',
          }, {
            label: 'Station ID',
            data_key: 'STORE_ID',
          }, {
            label: 'Station Name',
            data_key: 'STORE_NAME',
          }, {
            label: 'Terminal ID',
            data_key: 'TERMINAL_ID',
          }, {
            label: 'Terminal Name',
            data_key: 'TERMINAL_NAME',
          }, {
            label: 'Attendant Name',
            data_key: 'USERNAME',
          }, {
            label: 'RRN',
            data_key: 'RRN',
          }, {
            label: 'Txn Desc',
            data_key: 'TXN_DESC',
          }, {
            label: 'Product Name',
            data_key: 'PRODUCT_NAME',
          }, {
            label: 'Product Quantity',
            data_key: 'PRODUCT_QUANTITY',
          }, {
            label: 'Payment Mode',
            data_key: 'PAY_MODE',
          }, {
            label: 'Vehicle Reg',
            data_key: 'VEHREGNUM',
          }, {
            label: `Amount (${country.CURRENCY})`,
            data_key: 'AMOUNT',
          },
        ];

        data = statements.map(item => ({
          CARD_NUM: item.CARD_NUM,
          CARDNAME: item.CARDNAME,
          DATETIME: `${item.TRAN_DATE}:${item.TRAN_TIME}`,
          OPERATION_TYPE: item.OPERATION_TYPE,
          STORE_ID: item.STORE_ID,
          STORE_NAME: item.STORE_NAME,
          TERMINAL_ID: item.TERM_ID,
          TERMINAL_NAME: item.TERMINAL_NAME,
          USERNAME: item.USERNAME,
          RRN: item.RRN,
          TXN_DESC: item.TXN_DESC,
          PRODUCT_NAME: item.PRODUCT_NAME,
          PRODUCT_QUANTITY: item.PRODUCT_QUANTITY,
          PAY_MODE: item.PAY_MODE,
          VEHREGNUM: item.VEHREGNUM,
          AMOUNT: formatAmount(item.AMOUNT),
        }));
        report_data = statements.map(item => ({
          'Card number': item.CARD_NUM,
          'Card name': item.CARDNAME,
          'Date & Time': `${item.TRAN_DATE}:${item.TRAN_TIME}`,
          'TXN Type': item.OPERATION_TYPE,
          'Station Id': item.STORE_ID,
          'Station Name': item.STORE_NAME,
          'Terminal ID': item.TERM_ID,
          'Terminal Name': item.TERMINAL_NAME,
          'Attendant Name': item.USERNAME,
          RRN: item.RRN,
          'Txn Desc': item.TXN_DESC,
          'Product Name': item.PRODUCT_NAME,
          'Product Quantity': item.PRODUCT_QUANTITY,
          'Payment Mode': item.PAY_MODE,
          'Vehicle Reg': item.VEHREGNUM,
          AMOUNT: Number(item.AMOUNT),
        }));
      }

      if (type === 'stk-push-report') {
        labels = [
          {
            label: 'Card number',
            data_key: 'CARD_NUM',
          },
          {
            label: 'Card name',
            data_key: 'CARDNAME',
          },
          {
            label: 'Mobile Num',
            data_key: 'MOBILE_NUM',
          },
          {
            label: 'Date & Time',
            data_key: 'DATETIME',
          }, {
            label: 'TXN Type',
            data_key: 'OPERATION_TYPE',
          }, {
            label: 'Station ID',
            data_key: 'STORE_ID',
          }, {
            label: 'Station Name',
            data_key: 'STORE_NAME',
          }, {
            label: 'Terminal ID',
            data_key: 'TERMINAL_ID',
          }, {
            label: 'Terminal Name',
            data_key: 'TERMINAL_NAME',
          }, {
            label: 'Attendant Name',
            data_key: 'USERNAME',
          }, {
            label: 'RRN',
            data_key: 'RRN',
          }, {
            label: 'Txn Record Num',
            data_key: 'TXN_RECORD_NUM',
          }, {
            label: 'Txn Desc',
            data_key: 'TXN_DESC',
          }, {
            label: 'Product Name',
            data_key: 'PRODUCT_NAME',
          }, {
            label: 'Product Quantity',
            data_key: 'PRODUCT_QUANTITY',
          }, {
            label: 'Payment Mode',
            data_key: 'PAY_MODE',
          }, {
            label: 'Vehicle Reg',
            data_key: 'VEHREGNUM',
          }, {
            label: 'Fleet Num',
            data_key: 'FLEET_NUM',
          }, {
            label: 'Trip Id',
            data_key: 'TRIP_ID',
          },  {
            label: 'Route Id',
            data_key: 'ROUTE_ID',
          },{
            label: `Amount (${country.CURRENCY})`,
            data_key: 'AMOUNT',
          },
        ];

        data = statements.map(item => ({
          CARD_NUM: item.CARD_NUM,
          CARDNAME: item.CARDNAME,
          MOBILE_NUM: item.MOBILE_NUM,
          DATETIME: `${item.TRAN_DATE}:${item.TRAN_TIME}`,
          OPERATION_TYPE: item.OPERATION_TYPE,
          STORE_ID: item.STORE_ID,
          STORE_NAME: item.STORE_NAME,
          TERMINAL_ID: item.TERM_ID,
          TERMINAL_NAME: item.TERMINAL_NAME,
          USERNAME: item.USERNAME,
          RRN: item.RRN,
          TXN_RECORD_NUM: item.TXN_RECORD_NUM,
          TXN_DESC: item.TXN_DESC,
          PRODUCT_NAME: item.PRODUCT_NAME,
          PRODUCT_QUANTITY: item.PRODUCT_QUANTITY,
          PAY_MODE: item.PAY_MODE,
          VEHREGNUM: item.VEHREGNUM,
          FLEET_NUM: item.FLEET_NUM,
          TRIP_ID: item.TRIP_ID,
          ROUTE_ID: item.ROUTE_ID,
          ORDER: item.ORDER,
          AMOUNT: formatAmount(item.AMOUNT),
        }));
        report_data = statements.map(item => ({
          'Card number': item.CARD_NUM,
          'Card name': item.CARDNAME,
          'Mobile number': item.MOBILE_NUM,
          'Date & Time': `${item.TRAN_DATE}:${item.TRAN_TIME}`,
          'TXN Type': item.OPERATION_TYPE,
          'Station Id': item.STORE_ID,
          'Station Name': item.STORE_NAME,
          'Terminal ID': item.TERM_ID,
          'Terminal Name': item.TERMINAL_NAME,
          'Attendant Name': item.USERNAME,
          RRN: item.RRN,
          'Txn Record Num': item.TXN_RECORD_NUM,
          'Txn Desc': item.TXN_DESC,
          'Product Name': item.PRODUCT_NAME,
          'Product Quantity': item.PRODUCT_QUANTITY,
          'Payment Mode': item.PAY_MODE,
          'Vehicle Reg': item.VEHREGNUM,
          'Fleet No': item.FLEET_NUM,
          'Trip Id': item.TRIP_ID,
          'Route Id': item.ROUTE_ID,
          AMOUNT: Number(item.AMOUNT),
        }));
      }
      return {
 labels, data, report_data, title,
};
    };

    state.calculateBalances = () => {
      state.closingBal = state.statements.reduce((acc, card) => {
        const amount = !card.CLOSING_BALANCE || card.CLOSING_BALANCE === '-' ? 0 : Number(card.CLOSING_BALANCE || 0);
        return acc + amount;
      }, 0);
      state.openingBal = state.statements.reduce((acc, card) => {
        const amount = !card.OPENING_BAL || card.OPENING_BAL === '-' ? 0 : Number(card.OPENING_BAL || 0);
        return acc + amount;
      }, 0);
    };

    state.refreshModal = () => {
      selectOption.hide();
      selectCards.hide();
      selectFileType.hide();
    }

    state.handleSubmit = (path = 'genTxnFailureReport', filename = 'report', label, id) => {
      state.path = path;
      state.filename = filename;
      state.title = label;
      state.id = id;
      console.log('id', id)
      if (id === 'CLI_CARD_LIST') {
        if (CARDLIST) {
          const { filename, title } = state;
          state.tableData = state.mapTableData(CARDLIST, filename, title);
          state.statements = CARDLIST;
          state.isreport = true;
        } else {
          state.handleModalAction({ start: '', end: '', cards: []})
        }
      } else if (id === 'CLI_CARD_REPORT') {
        selectCards.show();
      } else {
        selectOption.show();
      }
    }

    state.handleModalAction = async ({ start = new Date(), end = new Date(), cards = [] }) => {
      try {
        state.loading = true;
        selectOption.hide();
        selectCards.hide();
        const { filename, title, path, id } = state;
        state.startDate = start;
        state.endDate = end;

        const {
          respcode,
          respmsg,
          respbody,
        } = await apirequest(`${path}`, 'POST', {
          ...state.reportPayload[id],
          ...(id === 'CLI_CARD_REPORT' ? {
             SEL_CARDNOLIST: getCardStrings(cards)
            } : {}),
          ...(accountData.USERTYPE === '@DEALER') && {
            MERCHANTID: accountData.ACCTNO,
            STOREID: stationList[0].STATIONID,
          },
          ...(id === 'CLI_CARD_LIST' ? {} : {
            FROMDATE: moment(start).format('DD-MM-YYYY'),
            TODATE: moment(end).format('DD-MM-YYYY'),
          }),
        });
        state.loading = false;

        if (respcode === 0 && respbody == null) {
          state.error = true;
          state.success = false;
          state.errorMessage = 'No records';
          setTimeout(() => {
            state.error = false;
            m.redraw();
          }, 4000);
          m.redraw();
        } else if (respcode === 0) {
          if (filename === 'txn-failure-report' || filename === 'anomaly-report') {
            if (state.download_type === 'pdf') {
              downloadPdfReport(respbody.DATA, filename);
            } else {
              downloadXSheetReport(respbody.DATA, filename);
            }
            state.success = true;
            state.error = false;
          } else {
            const data = respbody.STATMENTS || respbody.DATA || respbody.CARDLIST || [];
            state.tableData = state.mapTableData(data, filename, title);
            state.statements = data;
            state.isreport = (data.length) > 0;
            if (data.length > 0) {
              if (filename === 'running-balance') state.calculateBalances();
              state.success = true;
              state.error = false;
            } else {
              state.error = true;
              state.success = false;
              state.errorMessage = 'No records';
              setTimeout(() => {
                state.error = false;
                m.redraw();
              }, 4000);
              m.redraw();
            }
          }
        } else {
          state.error = true;
          state.success = false;
          state.errorMessage = respmsg;
          setTimeout(() => {
            state.error = false;
          }, 4000);
          m.redraw();
        }
        m.redraw();
      } catch (error) {
        console.log(error);
        state.loading = false;
        state.error = true;
        state.success = false;
        state.errorMessage = 'Failed to fetch the report';
        m.redraw();
      }
    };
    state.downloadTableXlsx = () => {
      const table = document.getElementById('report-id');
      const wb = XLSX.utils.table_to_book(table);

      /* Export to file (start a download) */
      XLSX.writeFile(wb, 'SheetJSTable.xlsx');
    };
    state.exportTable = async () => {
      try {
        state.downloading = true;
        let res;
        if (state.download_type === 'pdf') {
          res = await downloadPdf(state.filename);
        } else {
          res = await downloadSheetReportFromJson(state.tableData.report_data, state.title, state.filename);
        }

        state.downloading = false;
        if (res == 0) {
          state.downloadError = false;
        } else {
          state.downloadError = true;
          state.downloadErrorMsg = "Error downloading the report"
        }
        m.redraw()
      } catch (error) {
        state.downloading = false;
        state.downloadError = true;
        state.downloadErrorMsg = "Error downloading the report"
        m.redraw()
        console.log(error)
      }
    };

    state.handleFileSelect = (type) => {
      state.download_type = type;
      selectFileType.hide();
      state.exportTable();
    }

    state.fetchCardList = async () => {
      try {
        state.loading = state.cards.length == 0 ? true : false;
        const {
          respbody,
          respcode,
          respmsg
        } = await apirequest(`/ClientController/getCardList`, 'POST', {
            ACCTNO: accountData.ACCTNO, CARDNO: accountData.CARDNO,
        });
        if (respcode === 0) {
          state.cards = respbody?.CARDLIST || [];
        }
        return;
      } catch (error) {
        console.log(error);
      }
    };
    
    if (state.cards.length == 0 && accountData.USERTYPE === '@CLIENT' ) {
      state.fetchCardList();
    }
    // m.redraw();
  },
  // disable due to failures on some browsers
  oncreate() {},
  view({ state }) {
    // console.log({t:t()})
    return [
      // state.loading && m(loader),
      m(selectOption, { cards: state.cards, handleAction: state.handleModalAction, id: state.id, refresh: state.refreshModal }),
      m(selectCards, { cards: state.cards, handleAction: state.handleModalAction, id: state.id, refresh: state.refreshModal }),
      m(selectFileType, { handleAction: state.handleFileSelect }),

      // <!--begin::Header-->
      m(header, {
        logos: {
                  dark: 'assets/media/logos/rubis-logo-white.svg',
          light: 'assets/media/logos/rubis-logo-white.png',
        },
      }),
      // <!--end::Header-->

      // <!--begin::Wrapper-->
      m('div', { class: 'app-wrapper flex-column flex-row-fluid', id: 'kt_app_wrapper' }, [
        // <!--begin::Main-->
        m('div', { class: 'app-main flex-column flex-row-fluid', id: 'kt_app_main' }, [
          // <!--begin::Content wrapper-->
          m('div', { class: 'd-flex flex-column flex-column-fluid' }, [
            // <!--begin::Toolbar-->
            // m(toolbar),
            // <!--end::Toolbar-->

            // <!--begin::Content-->
            m('div', { class: 'app-container', id: 'kt_app_content_container', style: { margin: '30px' } }, [

              state.success && m('div', { class: 'text-success text-center fs-5 py-2' }, state.successMessage),
              state.error && m('div', { class: 'text-danger text-center fs-5 py-2' }, state.errorMessage),
              state.downloadError && m('div', { class: 'text-danger text-center fs-5 py-2' }, state.downloadErrorMsg),
              state.loading
                && m('div', { class: 'fs-3 py-3 text-center text-success' }, [
                  'Preparing report data. Please wait, it can take awhile.. ',
                  m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2 text-success' }),
                ]),

              state.isreport ? [
                m('div', { class: 'd-flex justify-content-center p-5' }, [
                  m('button', { class: 'btn border border-warning-subtle btn-light shadow rounded me-3', onclick: () => { state.isreport = false; } }, [
                    m('i', { class: 'fa-solid fa-arrow-left' }), 
                    'Back ',
                  ]),
                  m('button', { class: 'btn border border-warning-subtle btn-light shadow rounded', onclick: () => { selectFileType.show(); } }, state.downloading ? 
                  [
                    'Downloading...',
                    m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2 text-black' }),
                  ] : [
                    'Download ',
                      m('i', { class: 'fa-solid fa-download align-middle ms-2' }),
                  ]),
                ]),
                m('div', { class: 'app-container container-xxl', id: 'report-2' },
                  [
                    state.loading ? m('.linear-background') : m(table_data, {
                      title: state.tableData.title,
                      overideHeader: state.filename !== 'card-list',
                      customHeader: [
                        m('div', { class: 'text-sm' }, [
                          m('p.mb-3', {id: 'date-header', style: 'color: black; font-weight: 800; font-size: 10px'}, `${state.startDate} - ${state.endDate}`),
                      ])
                      ],
                      headerItems: [
                        state.filename === 'running-balance' ? [
                          m('div', { class: 'd-flex flex-row justify-content-right' },
                            m('div', { class: 'd-flex flex-column align-items-start mx-3' }, [
                              m('label', { class: 'fs-4 fw-medium px-2' }, 'Opening Balance:'),
                              m('label', { class: 'fs-3 fw-bolder px-2' }, formatAmount(state.openingBal)),
                            ]),
                            m('div', { class: 'd-flex flex-column align-items-start' }, [
                              m('label', { class: 'fs-4 fw-medium px-2' }, 'Closing Balance:'),
                              m('label', { class: 'fs-3 fw-bolder px-2' }, formatAmount(state.closingBal)),
                          ])),
                      ] : [],
                      ],
                      actionItems: [],
                      dataConfigs: {
                        sourceEndpoint: '/reports',
                        disableActions: true,
                        disableSelection: true,
                        actionItems: [{
                          label: t().cards.cards_request,
                        }],
                        dataMapping: [...state.tableData.labels],
                        dataRows: [...state.tableData.data],
                      },
                    }),
                  ]),
              ] : [
              // <!--begin::Inbox App - Messages -->
                m('div', { class: 'd-flex flex-column flex-lg-row row' }, [
                  // cards here
                  [{
                    id: `CARD_TXNFAILD_RPT`,
                    path: '/CardController/genTxnFailureReport',
                    filename: 'txn-failure-report',
                    label: 'Transaction Failure Report',
                    icon: `assets/media/icons/txn-fail.png`,
                  }, {
                    id: `CLI_TXNFAILD_RPT`,
                    path: '/ClientController/genTxnFailureReport',
                    filename: 'txn-failure-report',
                    label: 'Transaction Failure Report',
                    icon: `assets/media/icons/txn-fail.png`,
                  }, {
                    id: 'CLI_ANOMLY_RPT',
                    path: '/ClientController/genAnomanlyReport',
                    filename: 'anomaly-report',
                    label: t().reports.report_types.anomaly_report,
                    icon: `assets/media/icons/anormaly-report.png`,
                  },
                  {
                    id: 'CLI_RUNNING_RPT',
                    path: '/ClientController/runningbalance',
                    filename: 'running-balance',
                    label: 'Running Balance',
                    icon: `assets/media/icons/running-balance.png`,
                  },
                  {
                    id: `CLI_SUMM_RPT`,
                    path: '/ClientController/summeryStmt',
                    filename: 'summary-report',
                    label: 'Summary Report',
                    icon: `assets/media/icons/summary-report.png`,
                  },
                  {
                    id: `CLI_CARD_REPORT`,
                    path: '/ClientController/detailedreport',
                    filename: 'transactions-report',
                    label: 'Transactions Report',
                    icon: `assets/media/icons/summary-report.png`,
                  },
                  {
                    id: 'CLI_CARD_LIST',
                    path: '/ClientController/getCardList',
                    filename: 'card-list',
                    label: 'Card List Report',
                    icon: `assets/media/icons/running-balance.png`,
                  },
                  {
                    id: `CARD_SUMM_RPT`,
                    path: '/CardController/summeryStmt',
                    filename: 'summary-report',
                    label: 'Summary Report',
                    icon: `assets/media/icons/summary-report.png`,
                  },
                  {
                    id: `CARD_DETAIL_STMT`,
                    path: '/CardController/detailedstmt',
                    filename: 'txn-report',
                    label: 'Transaction Report',
                    icon: `assets/media/icons/summary-report.png`,
                  },  {
                    id: 'DEAL_SUMMARY_REPORT',
                    path: '/MerchantService/getTransactionSummaryRep',
                    filename: 'transaction-summary-report',
                    label: 'Transaction summary report',
                    icon: `assets/media/icons/summary-report.png`,
                  },
                  {
                    id: 'DEAL_DETAILED_REPORT',
                    path: '/MerchantService/getStoresBasedTransactionRep',
                    filename: 'detailed-report',
                    label: 'Detailed report',
                    icon: `assets/media/icons/summary-report.png`,
                  },
                  {
                    id: 'DEAL_STK_PUSH_RPT',
                    path: '/MerchantService/getDealerStkPushReport',
                    filename: 'stk-push-report',
                    label: 'STK push  report',
                    icon: `assets/media/icons/summary-report.png`,
                  },

                ].filter(({ id }) => !!state.accountData.MENULIST.find(menu => menu.MENUID === id))
                .map(({
 label, filename, icon, path, id,
}) => m('div', {
                      class: 'col-md-6 col-xl-4 pe-2 ps-2 pb-2 pt-2',
                      style: {
                        cursor: 'pointer',
                      },
                      onclick: () => { state.handleSubmit(path, filename, label, id); },
                    },
                      m('div', {
                        class: 'card card-flush h-md-100 report-card',
  style: {
                          // boxShadow: "0px 0px 10px rgba(0,0,0,0.5)"
                        },
                      },
                        m('div', {
                          class: 'card-header justify-content-start flex-nowrap pt-5 mb-6',
  style: {
                            margin: '30px',
                          },
                        },
                        m('h3', {
                          class: 'card-title align-items-start flex-column mr-3',
style: {
  width: '5rem'
                            // transform: "translateY(-20px)"
                          },
                        },
                          [
                            m('div', { class: 'd-flex align-items-center mb-2' },
                              [

                                m('span', { class: 'fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2' },
                                  m('img', {
class: '', style: 'height: 5rem;', alt: '', src: icon,
})),
                              ]),

                          ]),
                          m('h3', { class: 'card-title align-items-start flex-column' },
                            [
                              m('span', { class: 'fs-2hx fw-bold text-gray-800', style: 'font-size: 2.0rem !important;' },
                                m('b', label)),


                            ]),
                          )))),
                  // <!--end::Content-->
                ]),
              ],

              // <!--end::Inbox App - Messages -->
            ]),
            // <!--end::Content-->
          ]),
          m(footer),
        ]),
      ]),

    ];
  },
};
