/* eslint-disable no-param-reassign */
import m from 'mithril';

import { t, setSelectedLanguage } from '../../translation-files/translator';
import apirequest from '../../utils/api-service';
import {
 isEmailValid,
} from '../../utils/helper';

export default {
  oncreate() {
    window.KTComponents.init();
    document.body.className = '';
    document.body.style.backgroundImage = "url('assets/media/auth/bg-ug.jpg')";
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundRepeat = 'no-repeat';
    document.body.style.backgroundPosition = 'center';
    document.body.style.display = 'flex';
    document.body.style.alignItems = 'flex-end';
    document.body.style.justifyContent = 'center';
    document.querySelector('.app-page').style.display = 'flex';

    // center in page vertically
    document.querySelector('.app-page').style.justifyContent = 'start';
    document.getElementById('kt_app_root').style.paddingRight = '5%';
    document.getElementById('kt_app_root').style.paddingLeft = '5%';
    document.getElementById('kt_app_root').style.paddingTop = '5%';

    const appDiv = document.getElementById('app');
    if (appDiv) {
      // appDiv.style.width = '450px';
      // appDiv.style.minWidth = '450px';
      // appDiv.style.maxWidth = '450px';
    }

    document.body.style.backgroundSize = '105vw'; // set background size to 100% of viewport width
    document.body.style.backgroundPosition = 'center -13vw'; // adjust the vertical position of the background image
  },
  oninit: async ({ state }) => {
    state.logos = {
      dark: 'assets/media/logos/bill-logo.svg',
      light: 'assets/media/logos/bill-logo.svg',
    };

    state.loading = true;
    state.error = false;
    state.success = false;
    state.successMessage = '';
    state.errorMessage = '';
    state.customer_types = [];
    state.step = 1;
    state.customer_type = {};
    state.form_data_valid = false;
    state.form_valid = false;
    state.form_data = {
      cardno: '',
      merchantid: '',
      clientid: '',
    };
    state.user_details = {
      firstname: '',
      lastname: '',
      email: '',
      username: '',
    };
    state.form_errors = {
      firstname: false,
      lastname: false,
      username: false,
      email: false,
    };

    state.onchange = (e) => {
      state.form_data[e.target.name] = e.target.value;
      state.validateOnChange();
    };

    state.validateOnChange = () => {
      const custType = state.customer_type.PRODID;
      if (custType === '@CARD') {
        state.form_data_valid = state.form_data.cardno !== '';
      } else if (custType === '@DEALER') {
        state.form_data_valid = state.form_data.merchantid !== '';
      } else if (custType === '@CLIENT') {
        state.form_data_valid = state.form_data.clientid !== '';
      }
      m.redraw();
    };
    state.handleInputValidations = () => {
      state.form_errors.firstname = state.user_details.firstname === '';
      state.form_errors.lastname = state.user_details.lastname === '';
      state.form_errors.username = state.user_details.username === '';
      state.form_errors.email = !isEmailValid(state.user_details.email);
      state.form_valid = !state.form_errors.firstname && !state.form_errors.lastname
       && !state.form_errors.username && !state.form_errors.email;
    };

    state.oninput = (e) => {
      state.user_details[e.target.name] = e.target.value;
      state.handleInputValidations();
    };

    state.loadCustomerTypes = async () => {
      try {
          state.loading = true;
          const res = await apirequest('/UserController/getProductTypes', 'get');
          if (res.respbody) {
            const list = res.respbody.PRODLIST;
              state.customer_types = [...list];
              state.customer_type = { ...list[0] };
          }
          state.loading = false;
          m.redraw();
      } catch (error) {
          state.loading = false;
          console.log('error', error);
          m.redraw();
      }
    };

    state.handleCreateUser = async () => {
      try {
        state.loading = true;

        const {
          respcode,
          respmsg,
        } = await apirequest(`/UserController/doRegistration`, 'POST', {
          LASTNAME: state.user_details.lastname,
          USERNAME: state.user_details.username,
          FIRSTNAME: state.user_details.firstname,
          EMAIL: state.user_details.email,
          USERTYPE: state.customer_type.PRODID,
          ...(state.customer_type.PRODID === '@CARD' && { CARDNO: state.form_data.cardno }),
          ...(state.customer_type.PRODID === '@DEALER' && { CARDNO: state.form_data.merchantid }),
          ...(state.customer_type.PRODID === '@CLIENT' && { CARDNO: state.form_data.clientid }),
        });
        state.loading = false;

        if (respcode === 0) {
          state.success = true;
          state.successMessage = 'Registered successfully';
          state.error = false;
          setTimeout(() => {
            m.route.set('/');
          }, 2000);
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        m.redraw();
      } catch (error) {
        console.log(error);
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Failed to register';
      }
    };

    state.checkCardAvailable = async () => {
      try {
        state.loading = true;

        const {
          respcode,
          respmsg,
        } = await apirequest(`/UserController/checkCardAvailable`, 'POST', {
          USERTYPE: state.customer_type.PRODID,
          ...(state.customer_type.PRODID === '@CARD' && { CARDNO: state.form_data.cardno }),
          ...(state.customer_type.PRODID === '@DEALER' && { CARDNO: state.form_data.merchantid }),
          ...(state.customer_type.PRODID === '@CLIENT' && { CARDNO: state.form_data.clientid }),
        });
        state.loading = false;

        if (respcode === 0) {
          state.error = false;
          state.checkCardAlreadyReg();
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        m.redraw();
      } catch (error) {
        console.log(error);
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Failed to validate Card';
      }
    };

    state.checkCardAlreadyReg = async () => {
      try {
        state.loading = true;

        const {
          respcode,
          respmsg,
        } = await apirequest(`/UserController/checkCardAlreadyReg`, 'POST', {
          USERTYPE: state.customer_type.PRODID,
          ...(state.customer_type.PRODID === '@CARD' && { CARDNO: state.form_data.cardno }),
          ...(state.customer_type.PRODID === '@DEALER' && { CARDNO: state.form_data.merchantid }),
          ...(state.customer_type.PRODID === '@CLIENT' && { CARDNO: state.form_data.clientid }),
        });
        state.loading = false;

        if (respcode === 0) {
          state.error = false;
          state.step = 2;
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        m.redraw();
      } catch (error) {
        console.log(error);
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Failed to validate Card';
      }
    };

    state.checkUsernameAvail = async () => {
      try {
        state.loading = true;

        const {
          respcode,
          respmsg,
        } = await apirequest(`/UserController/checkUsernameAvail`, 'POST', {
          USERNAME: state.user_details.username,
        });
        state.loading = false;

        if (respcode === 0) {
          state.error = false;
          state.handleCreateUser();
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        m.redraw();
      } catch (error) {
        console.log(error);
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Failed to validate Username';
      }
    };
    state.loadCustomerTypes();
    m.redraw();
  },
  onremove() {
    document.body.className = '';
    document.body.style.backgroundImage = '';
    document.body.style.backgroundSize = '';
    document.body.style.backgroundRepeat = '';
    document.body.style.backgroundPosition = '';
    document.body.style.display = '';
    document.body.style.alignItems = '';
    document.body.style.justifyContent = '';

    document.querySelector('.app-page').style.display = '';
    document.querySelector('.app-page').style.justifyContent = '';

    document.getElementById('kt_app_root').style.paddingLeft = '';
    document.getElementById('kt_app_root').style.paddingTop = '';

    const appDiv = document.getElementById('app');
    if (appDiv) {
      appDiv.style.width = '';
      appDiv.style.minWidth = '';
      appDiv.style.maxWidth = '';
    }
  },
  view({ state }) {
    return [
      m('div', {
        class: 'd-flex align-items-center justify-content-center flex-column',
      },
      m('div', { class: 'card shadow-sm' },
        [
          m('div', { class: 'card-body' }, [
            // m("iframe", { "src": "http://localhost:3000/kkportal/loginLink.do", "width": "100%", "height": "500", "frameBorder": "0" }),

            m('label', {
              class: 'card-title text-center',
              style: {
                'font-size': '2rem',
                'font-weight': '850',
                display: 'block',
                'text-align': 'center',
              },
            },
            t().auth_login.login.label),
            state.step === 1 ? [
              m('div', { class: 'card-title text-center', style: { 'font-size': '1.5rem' } },
                m('span', {
                  class: 'text-truncate',
                  style: {
                    'text-overflow': 'ellipsis',
                    overflow: 'hidden',
                    'white-space': 'wrap',
                    'max-width': '100px', // adjust the width to change the truncation limit
                  },
                },
                'Sign Up')),
              (state.success && [m('div', { class: 'text-success text-center fs-5 py-4' }, state.successMessage)]),

              m('div', { class: 'mb-10' }, [
                m('label', { class: 'required form-label', for: 'exampleFormControlInput1' },
                  t().auth_login.login.account_type.label),
                m('select', {
                  class: 'form-select',
                  'aria-label': 'Select example',
                  onchange: (e) => {
                    state.customer_type = state.customer_types.find(type => type.PRODID === e.target.value);
                    state.validateOnChange();
                    m.redraw();
                  },
                  value: state.customer_type.PRODID || null,
                },
                [
                  state.customer_types.map(type => m('option', { value: type.PRODID },
                    type.PRODNAME)),
                ]),
              ]),

              m('form', {
                class: 'form w-100 fv-plugins-bootstrap5 fv-plugins-framework', novalidate: 'novalidate', id: 'kt_sign_in_form', 'data-kt-redirect-url': '/keen/demo7/index.html', action: '#',
              },
              [
                m('div', { class: 'mb-10' },
                  [
                    state.customer_type.PRODID === '@CARD' && [
                      m('label', { class: 'required form-label' },
                      'Card No'),
                      m('input', {
                        class: 'form-control',
                        type: 'text',
                        name: 'cardno',
                        required: true,
                        placeholder: 'Card No',
                        value: state.form_data.cardno,
                        oninput: e => state.onchange(e),
                      }),
                      state.form_data_valid ? [] : [
                        m('div', { class: 'text-danger fs-6 py-2' }, 'Card No is required'),
                      ],
                    ],
                    state.customer_type.PRODID === '@DEALER' && [
                      m('label', { class: 'required form-label', for: '' },
                      'Merchant Id'),
                        m('input', {
                          class: 'form-control',
                          type: 'text',
                          name: 'merchantid',
                          required: true,
                          placeholder: 'Merchant Id',
                          value: state.form_data.merchantid,
                          oninput: e => state.onchange(e),
                        }),
                        state.form_data_valid ? [] : [
                          m('div', { class: 'text-danger fs-6 py-2' }, 'Merchant Id is required'),
                        ],
                      ],
                    state.customer_type.PRODID === '@CLIENT' && [
                      m('label', { class: 'required form-label', for: '' },
                      'Client Id'),
                      m('input', {
                        class: 'form-control',
                        type: 'text',
                        name: 'clientid',
                        required: true,
                        placeholder: 'Client Id',
                        value: state.form_data.clientid,
                        oninput: e => state.onchange(e),
                      }),
                      state.form_data_valid ? [] : [
                        m('div', { class: 'text-danger fs-6 py-2' }, 'Client Id is required'),
                      ],
                    ],
                  ]),
                m('div', { class: 'd-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8' },
                  [
                    m('a', { class: 'link-primary', href: '/' },
                      'Back'),
                  ]),

                m('div', { class: 'd-grid mb-5' },
                  m('button', {
                    class: 'btn btn-primary',
                    type: 'submit',
                    id: 'kt_sign_in_submit',
                    disabled: state.loading || !state.form_data_valid,
                    onclick(e) {
                      e.preventDefault();
                      state.checkCardAvailable();
                    },
                  },
                  [
                    ...(!state.loading ? [m('span', { class: 'indicator-label' },
                      'Proceed')]
                    : [m('span', { class: '' },
                      [
                        ' Please wait... ',
                        m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2' }),
                      ])]),
                  ])),
                (state.error && [m('div', { class: 'text-danger text-center fs-5 py-2' }, state.errorMessage)]),

              ]),
            ] : [
              m('div', { class: 'card-title text-center', style: { 'font-size': '1.5rem' } },
                m('span', {
                  class: 'text-truncate',
                  style: {
                    'text-overflow': 'ellipsis',
                    overflow: 'hidden',
                    'white-space': 'wrap',
                    'max-width': '100px', // adjust the width to change the truncation limit
                  },
                },
                'User Details')),
                (state.success && [m('div', { class: 'text-success text-center fs-5 py-4' }, state.successMessage)]),

              m('form', {
                class: 'form w-100 fv-plugins-bootstrap5 fv-plugins-framework', novalidate: 'novalidate', id: 'kt_sign_in_form', 'data-kt-redirect-url': '/keen/demo7/index.html', action: '#',
              },
              [
                m('div', { class: 'mb-10' },
                  [
                    m('label', { class: 'required form-label', for: 'exampleFormControlInput1' },
                      t().auth_login.login.username.label),
                    m('input', {
                      class: 'form-control',
                      type: 'text',
                      name: 'username',
                      required: true,
                      placeholder: t().auth_login.login.username.placeholder,
                      value: state.user_details.username,
                      oninput: e => state.oninput(e),
                    }),
                    state.form_errors.username && m('div', { class: 'text-danger fs-6' }, 'Username is required'),
                  ]),
                  m('div', { class: 'mb-10' },
                    [
                      m('label', { class: 'required form-label', for: '' },
                        'First Name'),
                      m('input', {
                        class: 'form-control',
                        type: 'text',
                        name: 'firstname',
                        required: true,
                        placeholder: 'John',
                        value: state.user_details.firstname,
                        oninput: e => state.oninput(e),
                      }),
                      state.form_errors.firstname && m('div', { class: 'text-danger fs-6' }, 'First name is required'),
                    ]),
                    m('div', { class: 'mb-10' },
                      [
                        m('label', { class: 'required form-label', for: '' },
                          'Last Name'),
                        m('input', {
                          class: 'form-control',
                          type: 'text',
                          name: 'lastname',
                          required: true,
                          placeholder: t().auth_login.login.username.placeholder,
                          value: state.user_details.lastname,
                          oninput: e => state.oninput(e),
                        }),
                        state.form_errors.lastname && m('div', { class: 'text-danger fs-6' }, 'Last name is required'),
                      ]),
                      m('div', { class: 'mb-10' },
                        [
                          m('label', { class: 'required form-label', for: '' },
                            'Email'),
                          m('input', {
                            class: 'form-control',
                            type: 'email',
                            name: 'email',
                            required: true,
                            placeholder: 'test@mail.com',
                            value: state.user_details.email,
                            oninput: e => state.oninput(e),
                          }),
                          state.form_errors.email && m('div', { class: 'text-danger fs-6' }, 'Valid email is required'),
                        ]),

                m('div', { class: 'd-grid mb-5' },
                  m('button', {
                    class: 'btn btn-primary',
                    type: 'submit',
                    id: 'kt_sign_in_submit',
                    disabled: state.loading || !state.form_valid,
                    onclick(e) {
                      e.preventDefault();
                      state.checkUsernameAvail();
                    },
                  },
                  [
                    ...(!state.loading ? [m('span', { class: 'indicator-label' },
                      'Submit')]
                    : [m('span', { class: '' },
                      [
                        ' Please wait... ',
                        m('span', { class: 'spinner-border spinner-border-sm align-middle ms-2' }),
                      ])]),
                  ])),
                (state.error && [m('div', { class: 'text-danger text-center fs-5 py-2' }, state.errorMessage)]),

              ]),

            ],
          ]),

        ])),
    ];
  },
};
