/* eslint-disable no-param-reassign */
import m from 'mithril';
import moment from 'moment';
import header from '../../components/header';
import sidebar from '../../components/sidebar';
import table_data from '../../components/table_data';

import { t } from '../../translation-files/translator';
import footer from '../../components/footer';

import addTopUpModal from './modals/selectCard';
import topUpOptions from './modals/topUpOptions';
import apirequest from '../../utils/api-service';
import { debounce, formatAmount, getItemLocalStorage, setToLocalStorage, getCardStrings, hasResource } from '../../utils/helper';
import instituationConfig from '../../instituation-config';

const { ID } = instituationConfig[window.country || 'kenya'];

export default {
  async oninit({ state }) {
    const companyName = getItemLocalStorage('companyName');
    const accountData = getItemLocalStorage('accountData') || {};
    const globalCardType = getItemLocalStorage('globalCardType');
    const cardListData = getItemLocalStorage('cardListData');
    const statements = getItemLocalStorage('topupStatements');
    const paymentMethods = getItemLocalStorage('paymentMethods');
    
    const { CARDLIST, CARD_LIST } = cardListData || {};
    state.company = companyName || '';
    state.globalCardType = globalCardType;
    state.cards =  CARDLIST ? CARDLIST : (CARD_LIST ? CARD_LIST : []);
    state.statements = [];
    state.SEL_CARDNOLIST = [];
    state.selectedCards = [];
    state.loading = false;
    state.error = false;
    state.errorMessage = '';
    state.max = moment().format();
    state.cal_data = {
      start: moment().subtract(6, 'days').format().split('T')[0],
      end: moment().format().split('T')[0],
    };
    state.timer = undefined;
    state.paymentMethods = []
    state.debounce = (func, timeout = 2000) => {
      return function (...args) {
        if(state.timer ) clearTimeout(state.timer);

        state.timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    }
    state.handleRefresh = () => {
      localStorage.removeItem('topupStatements')
      location.reload();
    }
    state.fetchCardList = async () => {
      try {
        state.loading = state.cards.length == 0 ? true : false;
        const {
          respbody,
          respcode,
          respmsg,
        } = await apirequest(`/ClientController/getCardList`, 'POST', {
            ACCTNO: accountData.ACCTNO, CARDNO: accountData.CARDNO,
        });

        if (respcode === 0) {
          state.cards = respbody?.CARDLIST || [];
        } else {
          state.error = true;
          state.errorMessage = respmsg;
          setTimeout(() => {
            state.error = false;
          }, 30000)
        }
        state.loading = false;
        m.redraw();
        return;
      } catch (error) {
        state.loading = false;
        throw error;
      }
    };
    state.refresh = (card_num) => {
      state.fetchTopups();
      state.fetchCardData(card_num);
    };
    state.fetchTopups = async () => {
      try {
        // state.loading = true;
        const {
          respmsg,
          respcode,
          respbody,
        } = await apirequest(`/ClientController/getTopupDetails`, 'POST', {
            CUST_ID: accountData.CUSTID,
            ...(state.SEL_CARDNOLIST.length > 0 ? {
              SEL_CARDNOLIST: state.SEL_CARDNOLIST
            } : {})
        });
        if (respcode === 0) {
          let txns = respbody?.STATMENTS || []
          if (txns.length > 0 && state.selectedCards.length == 0) {
            setToLocalStorage('topupStatements', respbody?.STATMENTS)
          }
          state.statements = txns;
        } else {
          state.error = true;
          state.errorMessage = respmsg;
          setTimeout(() => {
            state.error = false;
          }, 30000)
        }
        state.loading = false;
        m.redraw();
      } catch (error) {
        // state.loading = false;
        state.error = true;
        state.loading = false;
        state.errorMessage = 'Something went wrong';
        console.log(error);
        m.redraw();
      }
    };
    state.handleCalChange = (e) => {
      state.cal_data[e.target.name] = e.target.value;
      state.fetchTopups();
    };

    state.handelCardSelection = () => {
      const selectedItems = $('#mySelect2').select2('data');
      state.selectedCards = selectedItems;
      state.SEL_CARDNOLIST = getCardStrings(selectedItems);
      state.debounce(() => {
        state.fetchTopups();
      }, 4000)();
    };
    state.fetchCardData = async (card_num) => {
      try {
        const {
          respcode,
          respmsg,
          respbody,
        } = await apirequest(`/ClientController/getCardDetails`, 'POST', {
          ACCTNO: accountData.ACCTNO, CUST_ID: accountData.CUSTID, CARD_NUM: card_num
        });

        if (respcode === 0) {
          const cards = state.cards.map(card => {
            if (card.CARD_SERIAL_NUM === respbody.CARD_SERIAL_NUM) {
              return {...card, ...respbody}
            } 
            return card;
          })
          setToLocalStorage('cardListData', { CARDLIST: cards });
    
          state.cards = cards.filter(item => item.STATUS !== '11' && item.STATUS !== '13' && item.STATUS !== '16');
        } else {
          state.error = true;
          state.errorMessage = respmsg;
        }
        state.loading = false;
        
      } catch (error) {
        console.log('error:', error)
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Something went wrong';
      }
    };
    state.fetchPaymentMethods = async () => {
      try {
        const {
          respbody,
          respcode,
          respmsg,
        } = await apirequest(`/RubisMobileApiController/paymentMethod`, 'POST', {
            CLIENT_ID: "production:828bbd95e6e-d286-4bc9-9775-320422132a8a1721641995939",
            DEVICE_ID: "production:828bbd95e6e-d286-4bc9-9775-320422132a8a1721641995939",
            PAY_METHOD: "@TOPUP"
        });

        if (respcode === 0) {
          state.paymentMethods = respbody.PAY_OPTIONS?.filter((method) => ['@MPESA', '@MTNMOMO'].includes(method.ID)) || [];
          if(state.paymentMethods.length > 0) {
            setToLocalStorage('paymentMethods', state.paymentMethods)
          }
        } else {
          state.error = true;
          state.errorMessage = respmsg;
          setTimeout(() => {
            state.error = false;
          }, 30000)
        }
        m.redraw();
        return;
      } catch (error) {
        throw error;
      }
    };
    if (state.cards.length == 0) {
      state.fetchCardList(); // fetch cardlist
    }
    if (statements) {
      let txns = statements;
      state.statements = txns;
      state.loading = false;
      m.redraw();
    } else {
      state.fetchTopups();
    }
    if (paymentMethods) {
      state.paymentMethods = paymentMethods;
    } else {
      state.fetchPaymentMethods();
    }
  },
  // disable due to failures on some browsers
  oncreate() {
    $('#mySelect2').select2()
  },
  view({ state }) {
    return [
      // <!--begin::Header-->
      m(header, {
        logos: {
                  dark: 'assets/media/logos/rubis-logo-white.svg',
          light: 'assets/media/logos/rubis-logo-white.png',
        },
      }),
      // <!--end::Header-->

      // <!--begin::Wrapper-->
      m('div', { class: 'app-wrapper flex-column flex-row-fluid', id: 'kt_app_wrapper' }, [
        // <!--begin::Main-->
        m('div', { class: 'app-main flex-column flex-row-fluid', id: 'kt_app_main' }, [
          // <!--begin::Content wrapper-->
          m('div', { class: 'd-flex flex-column flex-column-fluid' }, [
            // <!--begin::Toolbar-->
            // m(toolbar),
            // <!--end::Toolbar-->

            // <!--begin::Content-->
            m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' }, [
              state.error && m('div', { class: 'text-danger text-center fs-5 py-2' }, state.errorMessage),
              // <!--begin::Inbox App - Messages -->
              m('div', { class: 'd-flex flex-column flex-lg-row' }, [
                // <!--begin::Sidebar-->
                m(sidebar, {
                  sideBarItems: [{
                    label: t().card_to_card.sidebar_labels.top_up,
                    icon: 'bi bi-book',
                    svg: `<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6023 16.636C10.7302 16.7128 10.8671 16.7468 11 16.7451C11.1329 16.7468 11.2698 16.7128 11.3977 16.636L12.533 15.9549C14.7595 14.619 17.4825 14.4008 19.8933 15.3651C20.7826 15.7208 21.75 15.0659 21.75 14.1081V2.44918C21.75 1.87123 21.3981 1.35151 20.8615 1.13686C17.7564 -0.105172 14.2492 0.175851 11.3815 1.89647L11 2.12539L10.6185 1.89647C7.75077 0.175851 4.24357 -0.105172 1.13848 1.13686C0.60187 1.35151 0.25 1.87123 0.25 2.44918V14.1081C0.25 15.0659 1.21739 15.7208 2.10672 15.3651C4.51752 14.4008 7.24052 14.619 9.46701 15.9549L10.6023 16.636ZM1.75 2.50801V13.8948C4.54579 12.8468 7.67179 13.1285 10.2388 14.6686L10.25 14.6754V3.42467L9.84673 3.18271C7.39774 1.71332 4.40634 1.46498 1.75 2.50801ZM11.7612 14.6686L11.75 14.6754V3.42467L12.1533 3.18271C14.6023 1.71332 17.5937 1.46498 20.25 2.50801V13.8948C17.4542 12.8468 14.3282 13.1285 11.7612 14.6686Z" fill="black"/>
                    <path d="M8.27516 17.0423C6.2513 15.8617 3.7487 15.8617 1.72484 17.0423L1.6221 17.1022C1.26431 17.3109 1.14346 17.7701 1.35217 18.1279C1.56088 18.4857 2.02011 18.6066 2.3779 18.3979L2.48065 18.3379C4.03746 17.4298 5.96254 17.4298 7.51935 18.3379L8.60661 18.9722C10.0856 19.8349 11.9144 19.8349 13.3934 18.9722L14.4806 18.3379C16.0375 17.4298 17.9625 17.4298 19.5194 18.3379L19.6221 18.3979C19.9799 18.6066 20.4391 18.4857 20.6478 18.1279C20.8565 17.7701 20.7357 17.3109 20.3779 17.1022L20.2752 17.0423C18.2513 15.8617 15.7487 15.8617 13.7248 17.0423L12.6376 17.6765C11.6257 18.2668 10.3743 18.2668 9.36242 17.6765L8.27516 17.0423Z" fill="black"/>
                    </svg>`,
                    route: '/funds',
                  },
                  ...(hasResource('CLI_CARD_TO_CARD') ? [{
                    label: t().card_to_card.sidebar_labels.card_to_card,
                    icon: 'bi bi-book',
                    svg: `<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6023 16.636C10.7302 16.7128 10.8671 16.7468 11 16.7451C11.1329 16.7468 11.2698 16.7128 11.3977 16.636L12.533 15.9549C14.7595 14.619 17.4825 14.4008 19.8933 15.3651C20.7826 15.7208 21.75 15.0659 21.75 14.1081V2.44918C21.75 1.87123 21.3981 1.35151 20.8615 1.13686C17.7564 -0.105172 14.2492 0.175851 11.3815 1.89647L11 2.12539L10.6185 1.89647C7.75077 0.175851 4.24357 -0.105172 1.13848 1.13686C0.60187 1.35151 0.25 1.87123 0.25 2.44918V14.1081C0.25 15.0659 1.21739 15.7208 2.10672 15.3651C4.51752 14.4008 7.24052 14.619 9.46701 15.9549L10.6023 16.636ZM1.75 2.50801V13.8948C4.54579 12.8468 7.67179 13.1285 10.2388 14.6686L10.25 14.6754V3.42467L9.84673 3.18271C7.39774 1.71332 4.40634 1.46498 1.75 2.50801ZM11.7612 14.6686L11.75 14.6754V3.42467L12.1533 3.18271C14.6023 1.71332 17.5937 1.46498 20.25 2.50801V13.8948C17.4542 12.8468 14.3282 13.1285 11.7612 14.6686Z" fill="black"/>
                    <path d="M8.27516 17.0423C6.2513 15.8617 3.7487 15.8617 1.72484 17.0423L1.6221 17.1022C1.26431 17.3109 1.14346 17.7701 1.35217 18.1279C1.56088 18.4857 2.02011 18.6066 2.3779 18.3979L2.48065 18.3379C4.03746 17.4298 5.96254 17.4298 7.51935 18.3379L8.60661 18.9722C10.0856 19.8349 11.9144 19.8349 13.3934 18.9722L14.4806 18.3379C16.0375 17.4298 17.9625 17.4298 19.5194 18.3379L19.6221 18.3979C19.9799 18.6066 20.4391 18.4857 20.6478 18.1279C20.8565 17.7701 20.7357 17.3109 20.3779 17.1022L20.2752 17.0423C18.2513 15.8617 15.7487 15.8617 13.7248 17.0423L12.6376 17.6765C11.6257 18.2668 10.3743 18.2668 9.36242 17.6765L8.27516 17.0423Z" fill="black"/>
                    </svg>`,
                    route: '/cardToCard',
                  }] : []),
                ],
                }),
                // <!--end::Sidebar-->
                // <!--begin::Content-->
                m('div', {
 class: 'app-content',
id: 'kt_app_content',
style: {
                  'flex-grow': 1,
                  'flex-basis': 'auto',
                },
},
                  m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' },
                    [
                      state.cards?.length === 0 ? null : m(addTopUpModal, {
                        cards: state.cards,
                        refresh: state.refresh,
                        paymentMethods: state.paymentMethods
                      }),
                      m(topUpOptions),
                      state.loading ? m('.linear-background') : m(table_data, {
                        title: t().funds.title,
                        actionItems: [
                          m('div', { class: 'd-flex justify-content-between align-items-center flex-wrap flex-lg-nowrap PX-5', 'data-kt-customer-table-toolbar': 'base' },
                            [
                              m('span', { class: 'fs-4 fw-bold' }, 'Recent Topup Transactions'),
                              m('form', { class: 'px-2 d-flex justify-content-start align-items-center flex-nowrap flex-lg-nowrap' }, [
                                // m('div.form-group.mx-2.mb-2.flex-grow-1.flex-sm-grow-0', [
                                //   m('label', 'From date:'),
                                //   m('input.form-control[type="date"]', {
                                //     value: state.cal_data.start,
                                //     name: 'start',
                                //     max: state.max.split('T')[0],
                                //     onchange: e => state.handleCalChange(e),
                                //   }),
                                // ]),
                                // m('div.form-group.mx-2.mb-2.flex-grow-1.flex-sm-grow-0', [
                                //   m('label', 'To date:'),
                                //   m('input.form-control[type="date"]', {
                                //     placeholder: 'select end date',
                                //     value: state.cal_data.end,
                                //     name: 'end',
                                //     max: state.max.split('T')[0],
                                //     oninput: e => state.handleCalChange(e),
                                //   }),
                                // ]),
                                m('select',
                                  {
                                    class: 'form-select rounded-0 me-sm-2 ms-sm-2 my-4 my-sm-5',
                                    style: 'max-width: 40rem; min-width: 20rem',
                                    'data-control': 'select2',
        id: 'mySelect2',
                                    'data-placeholder': 'Select upto 4 cards to compare',
                                    multiple: 'multiple',
                                    onchange: () => {
                                      state.handelCardSelection();
                                    },
                                  }, [
                                  m('option'),
                                  state.cards?.map(card => m('option', { value: card.ENCCARDNUMBER }, `${card.CARDNUMBER} - ${card.CARDNAME}`)),
                                  // m('option', { value: '2' }, 'Option 2'),
                                ]),
                                m('button', {
                                  class: 'btn btn-primary mx-1 mx-sm-5 my-2 my-sm-0 px-0',
                                  style: 'min-width: 12rem',
                                  onclick(e) {
                                    e.preventDefault();
                                    state.handleRefresh();
                                  },
                                },
                                  'Refresh Topups'),
                                  ['RUBISKE'].includes(ID) ? m('div.form-group.mx-2.ms-2.mb-2.d-flex.align-items-center.flex-grow-1.flex-sm-grow-0', [
                                  m('button', {
 class: 'btn btn-primary my-2 my-sm-0', style: 'min-width: 12rem',
                                     onclick: (e) => {
                                      e.preventDefault();
                                      addTopUpModal.show();
                                    },
                                  }, 'Add New Top up'),
                                ]) : [],
                             ]),
                            ]),
                        ],
                        dataConfigs: {
                          sourceEndpoint: '/funds',
                          disableActions: true,
                          disableSelection: true,
                          actionItems: [],
                          dataMapping: [{
                            label: t().funds.funds_table_labels.card_number,
                            data_key: 'MASKED_CARD',
                          }, {
                            label: t().funds.funds_table_labels.card_name,
                            data_key: 'CARD_NAME',
                          },{
                            label: 'RRN',
                            data_key: 'RRN',
                          }, {
                            label: t().funds.funds_table_labels.amount,
                            data_key: 'ENTRY_AMOUNT',
                          }, {
                            label: 'Date & time',
                            data_key: 'TRAN_DATE',
                          }],
                          dataRows: state.statements?.map(({
                            MASKED_CARD, CARD_NAME, RRN, ENTRY_AMOUNT, TRAN_TIME, TRAN_DATE,
}) => ({
  MASKED_CARD, CARD_NAME, RRN, ENTRY_AMOUNT: formatAmount(ENTRY_AMOUNT), TRAN_DATE: `${TRAN_DATE}:${TRAN_TIME}`,
                          })),
                        },
                      }),
                    ])),
                // <!--end::Content-->
              ]),
              // <!--end::Inbox App - Messages -->
            ]),
            // <!--end::Content-->
          ]),
          m(footer),
        ]),
      ]),

    ];
  },
};
