/* eslint-disable no-param-reassign */
import m from 'mithril';
import moment from 'moment';
import header from '../../components/header';
import toolbar from '../../components/toolbar';
import sidebar from '../../components/sidebar';
import table_data from '../../components/table_data';

import { t } from '../../translation-files/translator';
import footer from '../../components/footer';
import apirequest from '../../utils/api-service';
import { getItemLocalStorage, formatAmount, debounce, setToLocalStorage } from '../../utils/helper';
import instituationConfig from '../../instituation-config';

const { ID } = instituationConfig[window.country || 'kenya'];

export default {
  async oninit({ state }) {
    const companyName = getItemLocalStorage('companyName');
    const accountData = getItemLocalStorage('accountData') || {};
    const globalCardType = getItemLocalStorage('globalCardType');
    const statements = getItemLocalStorage('cardStatements');
    state.company = companyName || '';
    state.globalCardType = globalCardType || '';
    state.statements = [];
    state.all_statements = [];
    state.SEL_CARDNOLIST = [];
    state.loading = true;
    state.errorMessage = '';
    state.max = moment().format();
    state.cal_data = {
      start: moment().subtract(6, 'days').format().split('T')[0],
      end: moment().format().split('T')[0],
    };
    state.filter_data = {
      search: ''
    }
    state.timer = undefined;
    state.debounce = (func, timeout = 2000) => {
      return function (...args) {
        if(state.timer ) clearTimeout(state.timer);

        state.timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    }
    state.handleChange = (e) => {
      state.filter_data[e.target.name] = e.target.value;
      state.debounce(() => state.handleFilter(), 2000)();
    };

    state.handleFilter = () => {
      let statements = [];
      const searchTxt = state.filter_data.search.toLowerCase();
      statements = state.all_statements.filter(({
        ENTRY_AMOUNT, RRN, CARD_NUM
      }) => ENTRY_AMOUNT?.toLowerCase().includes(searchTxt)
      || RRN?.toLowerCase().includes(searchTxt)
        || CARD_NUM?.toLowerCase().includes(searchTxt));

      state.statements = statements;
      m.redraw();
    };
    state.fetchTopups = async () => {
      try {
        state.loading = true;
        const {
          respcode,
          respmsg,
          respbody
        } = await apirequest(`/ClientController/getCardtocardDetails`, 'POST', {
            CUST_ID: accountData.CUSTID,
            ...(state.SEL_CARDNOLIST.length > 0 ? {
              SEL_CARDNOLIST: state.SEL_CARDNOLIST
            } : {})
        });
        if (respcode === 0) {
          let txns = respbody?.STATMENTS || [];
          if (txns.length > 0){
            setToLocalStorage('cardStatements', respbody?.STATMENTS)
          }
          // txns = txns.reduce((acc, item) => {
          //   const idx = acc.findIndex(ac => ac.RRN === item.RRN);
          //   if (idx >= 0) {
          //     acc[idx] = {
          //       ...acc[idx],
          //       [item.OPERATION_TYPE]: item,
          //     };
          //   } else {
          //     const newItem = {
          //       RRN: item.RRN,
          //       [item.OPERATION_TYPE]: item,
          //     };
          //     acc.push(newItem);
          //   }
          //   return acc;
          // }, []);
          console.log('txns', txns);
          state.all_statements = txns;
          state.handleFilter()
        } else {
          state.error = true;
          state.errorMessage = respmsg
          setTimeout(() => {
            state.error = false;
          }, 30000)
        }
        state.loading = false;
        m.redraw();
        return;
      } catch (error) {
        state.loading = false;
        state.error = true;
        state.errorMessage = 'Error fetching the data'
        m.redraw();
        console.log(error)
      }
    };
    state.handleCalChange = (e) => {
      state.cal_data[e.target.name] = e.target.value;
      state.fetchTopups();
    };
    state.handleCardRefresh = () => {
      state.loading = true;
      state.fetchTopups();
    }

    if (statements) {
      let txns = statements;
      state.all_statements = txns;
      state.statements = txns;
      state.loading = false;
      m.redraw();
    } else {
      state.fetchTopups();
    }
  },
  // disable due to failures on some browsers
  oncreate() { },
  view({ state }) {
    return [
      // <!--begin::Header-->
      m(header, {
        logos: {
          dark: 'assets/media/logos/rubis-logo-white.svg',
          light: 'assets/media/logos/rubis-logo-white.png',
        },
      }),
      // <!--end::Header-->

      // <!--begin::Wrapper-->
      m('div', { class: 'app-wrapper flex-column flex-row-fluid', id: 'kt_app_wrapper' }, [
        // <!--begin::Main-->
        m('div', { class: 'app-main flex-column flex-row-fluid', id: 'kt_app_main' }, [
          // <!--begin::Content wrapper-->
          m('div', { class: 'd-flex flex-column flex-column-fluid' }, [
            // <!--begin::Toolbar-->
            // m(toolbar),
            // <!--end::Toolbar-->

            // <!--begin::Content-->
            m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' }, [
              state.error && m('div', { class: 'text-danger text-center fs-5 py-2' }, state.errorMessage),
              // <!--begin::Inbox App - Messages -->
              m('div', { class: 'd-flex flex-column flex-lg-row' }, [
                // <!--begin::Sidebar-->
                m(sidebar, {
                  sideBarItems: [
                    ...(state.globalCardType === '@PRE' && ['RUBISKE', 'RUBISUG'].includes(ID) ? [{
                      label: t().card_to_card.sidebar_labels.top_up,
                      icon: 'bi bi-book',
                      route: '/funds',
                    }] : []),
                    {
                      label: t().card_to_card.sidebar_labels.card_to_card,
                      icon: 'bi bi-book',
                      route: '/cardToCard',
                    }],
                }),
                // <!--end::Sidebar-->
                // <!--begin::Content-->
                m('div', {
                  class: 'app-content',
                  id: 'kt_app_content',
                  style: {
                    'flex-grow': 1,
                    'flex-basis': 'auto',
                  },
                },
                  m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' },
                    [
                      state.loading ? m(".linear-background") : m(table_data, {
                        title: t().card_to_card.title,
                        actionItems: [
                          m('div', { class: 'd-flex flex-column flex-lg-row justify-content-between align-items-start align-items-lg-center px-5', 'data-kt-customer-table-toolbar': 'base', style: { 'padding-right': '10px' } },
                            [
                              m('span', { class: 'fs-4 fw-bold' }, 'Recent Transactions'),
                              m('form', { class: 'px-0 px-lg-2 d-flex flex-wrap', style: '' }, [
                                m('div.form-group.mx-2.mb-2.flex-grow-1', [
                                  m('label', 'Search:'),
                                  m('input.form-control[type="text"]', {
                                    placeholder: 'Search...',
                                    value: state.filter_data.search,
                                    name: 'search',
                                    oninput: e => state.handleChange(e),
                                  }),
                                ]),
                                m('div.form-group.mx-2.mb-2.d-flex.align-items-end.flex-grow-1.flex-sm-grow-0', [
                                  m('button', {
                                    class: 'btn btn-primary mx-1 mx-sm-2 mt-3 mt-sm-0 px-0',
                                    style: 'max-height: 3.4rem;min-width: 11rem;',
                                    onclick() {
                                      state.handleCardRefresh();
                                    },
                                  },
                                    'Refresh Balances'),
                                ]),
                                m('div.form-group.mx-2.mb-2.d-flex.align-items-end.flex-grow-1.flex-sm-grow-0', [
                                  m('button', {
                                     class: 'btn btn-primary',
                                      onclick: (e) => {
                                        e.preventDefault();
                                        m.route.set('/cardToCard/single')
                                      }
                                    }, t().card_to_card.table_action_items.add_new)
                                ])
                              ]),
                            ]),
                        ],
                        dataConfigs: {
                          sourceEndpoint: '/cardToCard',
                          disableActions: true,
                          disableSelection: true,
                          dataMapping: [{
                            label: t().card_to_card.cards_table_labels.from_card_column,
                            data_key: 'FROM_MASKED_CARD',
                          }, {
                            label: t().card_to_card.cards_table_labels.to_card_column,
                            data_key: 'TO_MASKED_CARD',
                          }, 
                          // {
                          //   label: 'RRN',
                          //   data_key: 'RRN',
                          // }, 
                          {
                            label: t().card_to_card.cards_table_labels.amount,
                            data_key: 'ENTRY_AMOUNT',
                          }, {
                            label: t().card_to_card.cards_table_labels.date,
                            data_key: 'TRAN_DATE',
                          }, 
                          // {
                          //   label: 'Time',
                          //   data_key: 'TRAN_TIME',
                          // }
                        ],
                          dataRows: state.statements.map(({ FROM_MASKED_CARD, TO_MASKED_CARD, ENTRY_AMOUNT, RRN, TRAN_DATE, TRAN_TIME }) => ({
                            FROM_MASKED_CARD,
                            TO_MASKED_CARD,
                            RRN,
                            ENTRY_AMOUNT: formatAmount(ENTRY_AMOUNT || 0),
                            TRAN_DATE,
                            TRAN_TIME,
                          })),
                        },
                      }),
                    ])),
                // <!--end::Content-->
              ]),
              // <!--end::Inbox App - Messages -->
            ]),
            // <!--end::Content-->
          ]),
          m(footer),
        ]),
      ]),

    ];
  },
};
