import m from 'mithril';
import modalCloseButton from '../../../components/modalCloseButton';
import apirequest from '../../../utils/api-service';
import { getItemLocalStorage, formatAmount, isValidPhoneNumber, isValidAmount } from '../../../utils/helper';
import instituationConfig from '../../../instituation-config';

const country = instituationConfig[window.country || 'kenya'];

const modal = {
  key: 'CARD_SELECTION_MODAL',

  show() {
    $(`#${modal.key}`).modal('show');
  },
  hide() {
    $(`#${modal.key}`).modal('hide');
  },
  oncreate: ({ state }) => {
    $(`#${modal.key}`)[0].addEventListener('hidden.bs.modal', event => {
      console.log('========Being triggered=======', state)
    })
  },
  oninit: async ({ state, attrs }) => {
    const accountData = getItemLocalStorage('accountData') || {};
    state.cards = attrs.cards || [];
    state.selectedCard = null;
    state.step = 1;
    state.mobile = true;
    state.phone = '';
    state.amount = '';
    state.formErrors = {},
    state.calling_code = country.calling_code || '254'
    state.handlechange = (e) => {
      if (e.target.name === 'phone') {
        const pattern = /^\d{0,9}$/;
        let val = e.target.value;
        val = pattern.test(val) ? val : state[e.target.name];

        if (val.startsWith('0') && val.length == 9) {
          val = val.substring(1);
        }
        state[e.target.name] = val;
      } else {
        state[e.target.name] = e.target.value;
      }
      state.validateFormFields();
    };
    state.changeToMobile = () => {state.mobile = true};
    state.changeToCard = () => {state.mobile = false};
    state.validateFormFields = () => {
      const pattern = /^\d{9}$/;
      state.formErrors = {
        phone: !pattern.test(state.phone),
        amount: !isValidAmount(state.amount)
      }
    }
    state.handleSubmit = async () => {
      try {
        state.step = 3;
        const {
          respcode, respmsg
        } = await apirequest(`/ClientController/cardTopUpByMpesa`, 'POST', {
            ACCTNO: accountData?.ACCTNO, 
            CARDNO: state.selectedCard.ENCCARDNUMBER,
            MPESA_NUMER: `${state.calling_code}${state.phone}`,
            AMOUNT: state.amount,
            CUSTID: accountData?.ACCTNO,
            USERNAME: accountData?.USERNAME,
        });

        console.log('respmsg', respmsg)
        if (respcode === 1) {
          state.errorMessage = respmsg
          state.step = 5;
        } else if (respcode === 0) {
          state.step = 4;
          attrs.refresh(state.selectedCard.ENCCARDNUMBER);
          setTimeout(() => {
            state.selectedCard = null;
            state.step = 1;
            state.errorMessage = '';
            modal.hide();
          }, 3000)
        } else {
          state.step = 5
        }
        console.log(state)
        m.redraw()
      } catch (error) {
        state.step = 5;
        console.log(error)
        state.errorMessage = 'Something went wrong'
      }
    }
  },
  view({ state, attrs }) {
    return m('.modal', { id: modal.key },
      [
        m('.modal-dialog.modal-dialog-centered[role="document"]',
          [
            m('.modal-content',
              [
                m('.modal-header',
                  [
                    state.step === 1 && m('h1.modal-title', !state.selectedCard ? 'Select a Card' : 'Card Preview'),
                    state.step === 2 && m('h1.modal-title', 'Payment'),
                    (state.step !== 1 && state.step !== 2) && m('h1.modal-title', ``),
                    m('div', { class: 'btn btn-icon btn-sm btn-active-light-primary ms-2', 'data-bs-dismiss': 'modal', 'aria-label': 'Close' },
                      m(modalCloseButton)),
                  ]),
                m('.modal-body',
                  [
                    state.step === 1 ?
                    m('form', [
                      m('div.form-group', [
                        m('label', m('h3', 'Pick a card:')),
                        m('select.form-control', {
                          onchange: (e) => {
                            state.selectedCard = state.cards.find(item => item.ENCCARDNUMBER === e.target.value);
                            if (state.selectedCard.MOBILENUM.substring(0, 3) == state.calling_code) {
                              state.phone = state.selectedCard.MOBILENUM.startsWith('0') ? state.selectedCard.MOBILENUM.substring(1) : state.selectedCard.MOBILENUM.substring(3)
                            }
                          },
                          placeholder: 'Tap to select card',
                        },
                          m('option', { value: '' }, 'Tap to select card'),
                          state.cards.map(card => m('option', { value: card.ENCCARDNUMBER }, card.CARDNUMBER))),

                      ]),

                      !state.selectedCard ? null
                      : [m('br'),
                      m('h3.modal-title', 'Card Information'),
                      m('div', { class: 'table-responsive' },
                        m('table', { class: 'table table-row-dashed table-row-gray-300' },
                          [
                            m('tbody',
                              [
                                m('tr',
                                  [
                                    m('td', m('b', 'Name')),
                                    m('td', state.selectedCard.CARDNAME),
                                  ]),
                                m('tr',
                                  [
                                    m('td', m('b', 'Card Number')),
                                    m('td', state.selectedCard.CARDNUMBER),
                                  ]),
                                m('tr',
                                  [
                                    m('td', m('b', 'Balance')),
                                    m('td', formatAmount(state.selectedCard.BALANCE)),
                                  ]),
                              ]),
                          ])),
                      m('button.btn.btn-primary[type="button"].w-100', {
                        onclick() {
                          state.step = 2;
                        },
                      }, 'Top Up Card')],
                    ]) : [],
                    state.step === 2 ?
                      m('form', [
                        m('div.form-group', [
                          m('label', m('h3', 'Card Number:')),
                          m('input', {
                            class: 'form-control',
                            value: state.selectedCard.CARDNUMBER,
                            disabled: true,
                            placeholder: '',
                          },),
  
                        ]),
                        m('label', { class: 'form-label mt-2' },
                          'Enter Topup AMount'),
                        m('input', {
class: 'form-control', type: 'number', min: '0', placeholder: '100', name: 'amount', required: true, value: state.amount, oninput: e => state.handlechange(e)
}),
state.formErrors.amount ? m('div', {class: 'text-danger py-2 fs-5'}, 'Enter valid amount. Should be a number more than 0') : [],
                        m('br'),
                        m('h3.modal-title', 'Payment Method'),
                        m('br'),
                        m('div', { style: { display: 'flex', justifyContent: 'space-between' } }, [
                          attrs.paymentMethods?.map((method) =>(
                            
                                  m('input', {
                                    class: 'btn-check', type: 'radio', name: 'radio_buttons_2', value: 'mpesa', id: 'kt_radio_buttons_2_option_1', onchange: state.changeToMobile,
                                  }),
                                  m('label', {
                                    class: 'btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-5',
          for: 'kt_radio_buttons_2_option_1',
          style: {
                                      height: 60,
                                      width: 130,
                                    },
                                  },
                                    [
                                      m('img', { class: '', style: 'height: auto; width: 7rem;', src: `${ method.THUMBNAIL ? method.THUMBNAIL : 'https://rubisuat.volanedev.com/rubis/cms_img/RUDXIMG004470_payment_option_mpesa.png'}` }),
                                    ])
                          ))
  //                         m('input', {
  //  class: 'btn-check', type: 'radio', name: 'radio_buttons_2', value: 'mastercard', id: 'kt_radio_buttons_3_option_3', onchange: state.changeToCard,
  // }),
  //                         m('label', {
  //                           class: 'btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center',
  // for: 'kt_radio_buttons_3_option_3',
  // style: {
  //                             height: 60,
  //                             width: 130,
  //                             'justify-content': 'center',
  //                           },
  //                         },
  //                           []),
                        ]),
                        m('div', [
                          !state.mobile ? null : m('div', { class: 'mb-10' },
                            [
                              m('label', { class: 'form-label' },
                                'Enter Mobile Number'),
                                m('div', { class: 'input-group' }, [
                                  m('span', { class: 'input-group-text', id: 'mobile' }, state.calling_code),
                                  m('input', {
                                    class: 'form-control',
                                    type: 'tel',
                                    name: 'phone',
                                    required: true,
                                    placeholder: '712345678',
                                    value: state.phone,
                                    oninput: e => state.handlechange(e),
                                  }),
                                ]),
  state.formErrors.phone ? m('div', {class: 'text-danger py-2 fs-5'}, `Enter valid mobile no e.g ${state.calling_code}701234567`) : [],
                              m('div', {
   'data-lastpass-icon-root': '',
  style: {
   position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important',
  },
  }),
                            ]),
                          state.mobile ? null : [m('div', { class: 'mb-10' },
                            [
                              m('label', { class: 'form-label' },
                                'Card Number'),
                              m('input', {
   class: 'form-control', type: 'email', placeholder: 'XXXX-XXXX-XXXX-1234', autocomplete: 'email',
  }),
                              m('div', {
   'data-lastpass-icon-root': '',
  style: {
   position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important',
  },
  }),
                            ]),
  
                          m('div', { class: 'row' },
                            [
                              m('div', { class: 'col-md-6' },
                                [
                                  m('div', { class: 'position-relative' },
                                    [
                                      m('input', {
   class: 'form-control form-control-solid', type: 'text', minlength: '3', maxlength: '4', placeholder: 'CVV', name: 'card_cvv',
  }),
                                      m('div', { class: 'position-absolute translate-middle-y top-50 end-0 me-3' },
                                        m('i', { class: 'ki-duotone ki-credit-cart fs-2hx' },
                                          [
                                            m('span', { class: 'path1' }),
                                            m('span', { class: 'path2' }),
                                          ])),
                                    ]),
                                ]),
                              m('div', { class: 'col-md-6' },
                                [
                                  m('div', { class: 'position-relative' },
                                    [
                                      m('input', {
   class: 'form-control form-control-solid', type: 'text', minlength: '3', maxlength: '4', placeholder: 'EXP', name: 'card_cvv',
  }),
                                      m('div', { class: 'position-absolute translate-middle-y top-50 end-0 me-3' },
                                        m('i', { class: 'ki-duotone ki-credit-cart fs-2hx' },
                                          [
                                            m('span', { class: 'path1' }),
                                            m('span', { class: 'path2' }),
                                          ])),
                                    ]),
                                ]),
                            ])],
  
  
                        ]),
                        m('br'),
                        m('div', { class: 'd-flex flex-column flex-sm-row'}, [
                          m('button.btn.btn-light btn-outline-secondary flex-fill mx-2', {
                            onclick() {
                              state.step = 1;
                              state.selectedCard = null;
                              state.formErrors = {};
                              state.amount = '';
                              state.phone = '';
                              modal.hide();
                            },
                          }, 'Cancel'),
                          m('button.btn.btn-primary flex-fill mx-2', {
                            disabled: state.formErrors.amount || state.amount <= 0 || (state.mobile && state.formErrors.phone),
                            onclick() {
                              state.handleSubmit()
                            },
                          }, 'Pay'),

                        ])
                      ]) : [],
                      state.step === 3 ? 
                      m('div', { class: "p-5 d-flex flex-column align-items-center" }, [
                        m('div', { class: 'fs-3 fw-semibold py-2' }, 'Payment being processed.'),
                        m('div', { class: 'fs-4 fw-normal py-2' }, `STK push has been sent to ${state.phone}.`),
                        m('div', { class: "spinner-grow text-success mt-3", style: 'height: 100px; width: 100px', role: "status"}, [
                          m('div', { class: 'visually-hidden' }, 'Loading...')
                        ])
                      ]) : [],
                      state.step === 4 ? 
                      m('div', { class: "p-5 d-flex flex-column align-items-center" }, [
                        m('h4', { class: 'fw-bold py-2 text-success' }, 'Payment processed successfully.'),
                        m('div', { class: "text-success mt-3"}, [
                          m('i', { class: 'fa-regular fa-circle-check text-success fw-bold', style: "font-size: 6rem;" })
                        ])
                      ]) : [],
                      state.step === 5 ? 
                      m('div', { class: "p-5 d-flex flex-column align-items-center" }, [
                        m('h2', { class: 'fw-bold py-2 text-danger' }, state.errorMessage || 'Payment failed.'),
                        m('div', { class: "my-3"}, [
                          m('i', { class: 'fa-regular fa-circle-xmark text-danger fw-bold', style: "font-size: 6rem;" })
                        ]),
                        m('br'),
                        m('div', { class: 'd-flex flex-column flex-sm-row'}, [
                          m('button.btn.btn-light btn-outline-secondary flex-fill mx-2', {
                            onclick() {
                              state.step = 1;
                              state.selectedCard = null;
                              state.formErrors = {};
                              state.amount = '';
                              state.phone = '';
                              modal.hide();
                            },
                          }, 'Cancel'),
                          m('button.btn.btn-primary flex-fill mx-2', {
                            onclick() {
                              state.step = 2
                            },
                          }, 'Retry'),
  
                        ])
                      ]) : [],
                  ]),
              ]),
          ]),
      ]);
  },
};

export default modal;
