import m from 'mithril';

const modal = {
  key: 'FAIL_MODAL',

  show() {
    $(`#${modal.key}`).modal('show');
  },
  hide() {
    $(`#${modal.key}`).modal('hide');
  },
  oninit() {
  },
  view({ attrs }) {
    return m('.modal', { id: modal.key },
        [
          m('.modal-dialog[role="document"]',
            [
              m('.modal-content',
                [
                  m('.modal-header',
                    [
                      m('h1.modal-title', ''),
                    ]),
                  m('.modal-body',
                    [

                        m('div', { class: 'p-5 d-flex flex-column align-items-center' }, [
                            m('h2', { class: 'fw-bold py-2 text-danger' }, attrs.message || 'Transfer failed.'),
                            m('div', { class: 'my-3' }, [
                              m('i', { class: 'fa-regular fa-circle-xmark text-danger fw-bold', style: 'font-size: 6rem;' }),
                            ]),
                            m('br'),
                            m('div', { class: 'd-flex flex-column flex-sm-row' }, [
                              m('button.btn.btn-primary flex-fill mx-2', {
                                onclick() {
                                  modal.hide();
                                },
                              }, 'Close'),

                            ]),
                          ]),
                    ]),
                ]),
            ]),
        ]);
  },
};


export default modal;
