/* eslint-disable no-param-reassign */
import m from 'mithril';
import moment from 'moment';
import header from '../../components/header';
import toolbar from '../../components/toolbar';
import sidebar from '../../components/sidebar';
import table_data from '../../components/table_data';

import { t } from '../../translation-files/translator';
import footer from '../../components/footer';
import loader from '../../components/loader';
import apirequest from '../../utils/api-service';
import { getItemLocalStorage, formatAmount, debounce } from '../../utils/helper';

export default {
  async oninit({ state }) {
    const companyName = getItemLocalStorage('companyName');
    const accountData = getItemLocalStorage('accountData') || {};
    const globalCardType = getItemLocalStorage('globalCardType');
    const stationList = getItemLocalStorage('stationList') || [];
    state.company = companyName || '';
    state.globalCardType = globalCardType;
    state.stations = [];
    state.loading = true; 
    state.all_statements = [];
    state.statements = [];
    state.txnTypes = [];
    state.selectedTxnType = 'all';
    state.loading = true;
    state.filter_data = {
      search: '',
      txn_type: 'all',
    };
    state.max = moment().format();
    state.cal_data = {
      start: moment().subtract(6, 'month').format().split('T')[0],
      end: moment().format().split('T')[0],
    };
    state.timer = undefined;
    state.debounce = (func, timeout = 2000) => {
      return function (...args) {
        if(state.timer ) clearTimeout(state.timer);

        state.timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    }
    state.handleCalChange = (e) => {
      state.cal_data[e.target.name] = e.target.value;
      state.debounce(() => state.fetchStations())();
    };
    state.handleChange = (e) => {
      state.filter_data[e.target.name] = e.target.value;
      state.debounce(() => state.handleFilter())();
    };
    state.handleSelect = (e) => {
      state.selectedTxnType = e.target.value
      state.handleFilter();
    }

    state.handleFilter = () => {
      let statements = [];
      const searchTxt = state.filter_data.search.toLowerCase();
      statements = state.all_statements.filter(({
        CARD_NUM, CARDNAME, RRN, TRAN_DATE, PRODUCT_NAME, TXN_DESC, OPERATION_TYPE, PAY_MODE
        }) => CARDNAME?.toLowerCase().includes(searchTxt)
        || CARD_NUM?.toLowerCase().includes(searchTxt)
        || RRN?.toLowerCase().includes(searchTxt)
        || PRODUCT_NAME?.toLowerCase().includes(searchTxt)
        || TRAN_DATE?.toLowerCase().includes(searchTxt)
        || TXN_DESC?.toLowerCase().includes(searchTxt)
        || PAY_MODE?.toLowerCase().includes(searchTxt)
        || OPERATION_TYPE?.toLowerCase().includes(searchTxt));

      if (state.selectedTxnType !== 'all') {
        statements = statements.filter(({ TXN_TYPE }) => TXN_TYPE === state.selectedTxnType)
      }

      state.statements = statements;
      m.redraw();
    };

    state.handleReset = () => {
      state.filter_data = {
        search: '',
      };
      state.selectedTxnType = 'all'
      state.cal_data = {
        start: moment().subtract(6, 'month').format().split('T')[0],
        end: moment().format().split('T')[0],
      };
      state.fetchTopups();
    }

    state.handleReset = () => {
      state.filter_data = {
        search: '',
      };
      state.fetchStations();
    }

    state.fetchStations = async () => {
      try {
        state.loading = true;
        const {
          respbody: {
            DATA,
          },
        } = await apirequest(`/MerchantService/getStoresBasedTransactionRep`, 'POST', {
          MERCHANTID: accountData.ACCTNO,
          STOREID: stationList[0].STATIONID,
          FROMDATE: moment(state.cal_data.start).format('DD-MM-YYYY'),
          TODATE: moment(state.cal_data.end).format('DD-MM-YYYY'),
        });
        state.all_statements = DATA;
        state.handleFilter();
        state.loading = false;
        m.redraw();
        return;
      } catch (error) {
        throw error;
      }
    };
    state.fetchStations();
  },
  // disable due to failures on some browsers
  oncreate() {},
  view({ state }) {
    return [
      state.loading ? m(loader) : [],
      // <!--begin::Header-->
      m(header, {
        logos: {
                  dark: 'assets/media/logos/rubis-logo-white.svg',
          light: 'assets/media/logos/rubis-logo-white.png',
        },
      }),
      // <!--end::Header-->

      // <!--begin::Wrapper-->
      m('div', { class: 'app-wrapper flex-column flex-row-fluid', id: 'kt_app_wrapper' }, [
        // <!--begin::Main-->
        m('div', { class: 'app-main flex-column flex-row-fluid', id: 'kt_app_main' }, [
          // <!--begin::Content wrapper-->
          m('div', { class: 'd-flex flex-column flex-column-fluid' }, [
            // <!--begin::Toolbar-->
            // m(toolbar),
            // <!--end::Toolbar-->

            // <!--begin::Content-->
            m('div', { class: 'app-container container-xxl', id: 'kt_app_content_container' }, [
              // <!--begin::Inbox App - Messages -->
              m('div', { class: 'd-flex flex-column flex-lg-row' }, [
                
                // <!--end::Sidebar-->
                // <!--begin::Content-->
                m('div', {
 class: 'app-content',
id: 'kt_app_content',
style: {
                  'flex-grow': 1,
                  'flex-basis': 'auto',
                },
},
                  m('div', { class: 'app-container', id: 'kt_app_content_container' },
                    [
                      state.loading ? [] : m(table_data, {
                        title: 'Transactions',
                        actionItems: [
                          m('div', { class: 'd-flex justify-content-end', 'data-kt-customer-table-toolbar': 'base', style: { 'padding-right': '10px' } },
                            [
                              m('form', { class: 'px-2', style: 'display: flex;' }, [
                                m('div.form-group.mx-2', [
                                  m('label', 'Search:'),
                                  m('input.form-control[type="text"]', {
                                    placeholder: 'Search..',
                                    value: state.filter_data.search,
                                    name: 'search',
                                    oninput: e => state.handleChange(e),
                                  }),
                                ]),
                                m('div.form-group.mx-3', [
                                  m('label', 'Transaction type:'),
                                  m('select', {
                                    class: 'form-select me-2',
                                    'aria-label': 'Filter Transaction type',
                                    style: { margin: '0px' },
                                    value: state.selectedTxnType,
                                    onchange: (e) => { state.handleSelect(e); },
                                  }, [
                                    m('option', { value: 'all' }, 'All'),
                                    state.txnTypes?.map(option => m('option', { value: option }, option)),
                                  ]),
                                ]),
                                m('div.form-group.mx-2', [
                                  m('label', 'From date:'),
                                  m('input.form-control[type="date"]', {
                                    value: state.cal_data.start,
                                    name: 'start',
                                    max: state.cal_data.end,
                                    onchange: e => state.handleCalChange(e),
                                  }),
                                ]),
                                m('div.form-group.mx-2', [
                                  m('label', 'To date:'),
                                  m('input.form-control[type="date"]', {
                                    placeholder: 'select end date',
                                    value: state.cal_data.end,
                                    name: 'end',
                                    min: state.cal_data.start,
                                    max: state.max.split('T')[0],
                                    oninput: e => state.handleCalChange(e),
                                  }),
                                ]),
                                m('div.form-group.mx-2.d-flex.align-items-end', [
                                  m('button', { class: 'btn btn-primary', onclick: () => state.handleReset() }, 'Reset')
                                ])
                             ]),
                            ]),
                        ],
                        dataConfigs: {
                          sourceEndpoint: '/',
                          // disableSelection:true,
                          disableDeletion: true,
                          disableActions: true,
                          disableSelection: true,
                          dataMapping: [
                            {
                              label: 'Card number',
                              data_key: 'CARD_NUM',
                            },
                            {
                              label: 'Card name',
                              data_key: 'CARDNAME',
                            },
                            {
                              label: 'Date & Time',
                              data_key: 'DATETIME',
                            },{
                              label: 'TXN Type',
                              data_key: 'OPERATION_TYPE',
                            },{
                              label: 'Terminal ID',
                              data_key: 'TERMINAL_ID',
                            },{
                              label: 'Attendant Name',
                              data_key: 'USERNAME',
                            },{
                              label: 'RRN',
                              data_key: 'RRN',
                            },{
                              label: 'Txn Desc',
                              data_key: 'TXN_DESC',
                            },{
                              label: 'Product Name',
                              data_key: 'PRODUCT_NAME',
                            }, {
                              label: 'Product Quantity',
                              data_key: 'PRODUCT_QUANTITY',
                            }, {
                              label: 'Payment Mode',
                              data_key: 'PAY_MODE',
                            },{
                              label: 'Vehicle Reg',
                              data_key: 'VEHREGNUM',
                            }, {
                              label: `Amount (${country.CURRENCY})`,
                              data_key: 'AMOUNT',
                            },
                          ],
                          dataRows: state.statements.map(item => ({
                            CARD_NUM: item.CARD_NUM,
                            CARDNAME: item.CARDNAME,
                            DATETIME: `${item.TRAN_DATE}:${item.TRAN_TIME}`,
                            OPERATION_TYPE: item.OPERATION_TYPE,
                            STORE_ID: item.STORE_ID,
                            STORE_NAME: item.STORE_NAME,
                            TERMINAL_ID: item.TERM_ID,
                            TERMINAL_NAME: item.TERMINAL_NAME,
                            USERNAME: item.USERNAME,
                            RRN: item.RRN,
                            TXN_DESC: item.TXN_DESC,
                            PRODUCT_NAME: item.PRODUCT_NAME,
                            PRODUCT_QUANTITY: item.PRODUCT_QUANTITY,
                            PAY_MODE: item.PAY_MODE,
                            VEHREGNUM: item.VEHREGNUM,
                            AMOUNT: formatAmount(item.AMOUNT),
                          })),
                        },
                      }),
                    ])),
                // <!--end::Content-->
              ]),
              // <!--end::Inbox App - Messages -->
            ]),
            // <!--end::Content-->
          ]),
          m(footer),
        ]),
      ]),

    ];
  },
};
